<script setup>
import { reactive, defineProps, defineEmits, onMounted } from "vue";

import { useCartStore } from "@/stores/cart";

const cartStore = useCartStore();
const { incrementProduct, decrementProduct } = cartStore;

const emit = defineEmits(["close-cart-modal", "open-clean-sale-modal"]);

const props = defineProps([
  "itemId",
  "itemBarcode",
  "itemQuantity",
  "itemName",
  "itemPrice",
  "itemImageUrl",
]);

const state = reactive({
  itemImage: "",
  trashIcon: "remove-item-trash.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/common", false, /\.svg$/);
  return images("./" + img);
};

const addItem = (itemId) => {
  const index = cartStore.cart.items.findIndex(
    (item) => item.productId === itemId
  );
  incrementProduct(index);
};

const removeItem = (itemId) => {
  const index = cartStore.cart.items.findIndex(
    (item) => item.productId === itemId
  );

  decrementProduct(index);

  if (cartStore.getTotalItemsQuantity === 0) {
    emit("close-cart-modal");
  }
};

onMounted(() => {
  if (process.env.NODE_ENV === "development") {
    state.itemImage =
      props.itemImageUrl && props.itemImageUrl != ""
        ? `https://s3.amazonaws.com/stage.assets.onii.com.br/products/${props.itemBarcode}/product-main-image/${props.itemImageUrl}.png`
        : "https://placehold.co/110x110";
  } else {
    state.itemImage =
      props.itemImageUrl && props.itemImageUrl != ""
        ? `https://s3.amazonaws.com/assets.onii.com.br/products/${props.itemBarcode}/product-main-image/${props.itemImageUrl}.png`
        : "https://placehold.co/110x110";
  }
});
</script>

<template>
  <div class="div-item">
    <div class="div-item-left-elements">
      <div class="div-item-image">
        <img class="item-image" :src="state.itemImage" alt="Item" />
      </div>
    </div>

    <div class="div-item-right-elements">
      <p class="item-description">
        {{ props.itemName }}
      </p>
      <div class="div-price-and-quantity">
        <p class="item-price">
          R$
          {{
            Number(props.itemPrice / 100)
              .toFixed(2)
              .toString()
              .replace(".", ",")
          }}
        </p>
        <div class="quantity-container">
          <div
            v-if="props.itemQuantity >= 2"
            @click="removeItem(props.itemId)"
            class="container-column right-border item-counter-sign"
          >
            -
          </div>
          <div
            v-if="props.itemQuantity === 1"
            @click="removeItem(props.itemId)"
            class="container-column right-border"
          >
            <img :src="getImgUrl(state.trashIcon)" class="remove-item-icon" />
          </div>

          <div class="container-column item-quantity">
            {{ props.itemQuantity }}
          </div>
          <div
            @click="addItem(props.itemId)"
            class="container-column left-border item-counter-sign"
          >
            +
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.div-item {
  display: flex;
  justify-content: center;
  width: 47.75rem;
  height: 11.5rem;
  border-radius: 1.875rem;
  border: 1px solid #949494;
  margin-bottom: 1.8rem;
}
.div-item-left-elements {
  display: flex;
  align-items: center;
  padding-right: 2.5rem;
}
.div-item-image {
  width: 6.875rem;
  height: 6.875rem;
  border-radius: 1rem;
  overflow: hidden;
}
.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.div-item-right-elements {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.item-description {
  display: flex;
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.div-price-and-quantity {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.item-price {
  color: #2e3191;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.quantity-container {
  display: flex;
  width: 13rem;
  height: 3.9375rem;
  border-radius: 0.875rem;
  border: 1px solid #ec7000;
  margin-right: 2rem;
}
.container-column {
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 20px;
}
.left-border {
  border-left: 1px solid #ec7000;
}
.right-border {
  border-right: 1px solid #ec7000;
}
.item-quantity {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.item-counter-sign {
  display: flex;
  align-items: center;
  color: #ec7000;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.remove-item-icon {
  width: 1.25rem;
  height: 1.4375rem;
}
</style>
