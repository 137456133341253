<script setup>
import { reactive } from "vue";

import { useRouter } from "vue-router";

const router = useRouter();

const state = reactive({
  warningSign: "warning-sign-big.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/common", false, /\.svg$/);
  return images("./" + img);
};

const returnToCheckout = () => {
  router.replace("/barcode-reading");
};
</script>

<template>
  <div class="error-modal-modal">
    <div class="error-modal-modal-content">
      <p class="text-error-title">Falha na operação</p>
      <img
        :src="getImgUrl(state.warningSign)"
        alt="warning-sign"
        class="warning-sign"
      />

      <div class="div-button">
        <button class="btn-return" @click="returnToCheckout">Entendi</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error-modal-modal {
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  background-color: #ec7000;
}
.error-modal-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.warning-sign {
  margin-bottom: 12rem;
}
.text-error-title {
  margin-top: 12rem;
  margin-bottom: 12rem;
  color: #fff;
  text-align: center;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.div-button {
  display: flex;
  justify-content: center;
}
.btn-return {
  width: 37.5rem;
  height: 7.5rem;
  border-radius: 0.625rem;
  background: #fff;
  color: #ec7000;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: unset;
}
</style>
