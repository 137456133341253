<script setup>
import { reactive, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";

import ListItem from "@/components/transaction-page/ListItem.vue";

import { useCartStore } from "@/stores/cart";
import { useWarningStore } from "@/stores/warning";

const warningStore = useWarningStore();
const cartStore = useCartStore();

const router = useRouter();

const state = reactive({
  transactionTicketImage: "validating-ticket.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../assets/img/common", false, /\.svg$/);
  return images("./" + img);
};

const goToWelcomeScreen = () => {
  router.replace("/welcome");
};

onBeforeUnmount(() => {
  warningStore.clearCounter();
});

onMounted(() => {
  warningStore.resetCounter();

  // Disabling warning timer in development mode
  if(process.env.NODE_ENV === "production") {
  warningStore.startCounter();
  document
    .getElementById("transaction-finished-page")
    .addEventListener("touchstart", () => {
      warningStore.resetCounter();
    });
  }
});
</script>

<template>
  <div id="transaction-finished-page" class="transaction-finished-page">
    <img
      :src="getImgUrl(state.transactionTicketImage)"
      alt="ticket"
      class="image-ticket"
    />

    <p class="text-sale-finished">Venda Finalizada</p>
    <p class="text-sale-content">
      Parabéns!<br />
      Sua compra foi concluída com sucesso!
      <br />Queremos expressar nossa sincera gratidão por escolher nossa loja
      para realizar sua compra. <br /><br />
      Nosso compromisso é sempre oferecer produtos de alta qualidade e um
      serviço excepcional. Esperamos que sua experiência de compra tenha sido
      tão agradável quanto possível.
    </p>

    <p class="text-sale-bought">Você comprou:</p>

    <div class="items-container">
      <list-item
        v-for="item in cartStore.cart.items"
        :key="item.productId"
        :itemQuantity="item.quantity"
        :itemName="item.name"
        :itemPrice="item.price"
        :itemImageUrl="item.imageURL"
        :itemBarcode="item.barcode"
      />
    </div>

    <div class="div-button">
      <button class="btn-finish" @click="goToWelcomeScreen">Finalizar</button>
    </div>
  </div>
</template>

<style scoped>
.transaction-finished-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-ticket {
  margin-top: 10rem;
  margin-bottom: 3.5rem;
}
.text-sale-finished {
  font-size: 3rem;
  font-weight: 700;
  color: #ec7000;
  margin-bottom: 3rem;
}
.text-sale-content {
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 5rem;
  margin-right: 5rem;
}
.text-sale-bought {
  font-size: 3rem;
  font-weight: 700;
  color: #ec7000;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.items-container {
  width: 100%;
  max-height: 337px;
  overflow-y: scroll;
}
.items-container::-webkit-scrollbar {
  width: 0.75rem;
}
.items-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.items-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgba(217, 217, 217, 0.50);
}
.div-button {
  position: fixed;
  bottom: 6rem;
  display: flex;
  justify-content: center;
}
.btn-finish {
  width: 37.5rem;
  height: 7.5rem;
  border-radius: 0.625rem;
  background: #ec7000;
  color: #fff;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: unset;
}
</style>
