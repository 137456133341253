<script setup>
import { reactive, onMounted, computed, defineEmits } from "vue";

import CartItem from "@/components/cart-modal/CartItem.vue";
import ActionButton from "@/components/common/Button.vue";

import { useCartStore } from "@/stores/cart";

const cartStore = useCartStore();

const emit = defineEmits([
  "close-cart-modal",
  "open-checkout-and-close-cart-modal",
  "open-clean-sale-modal",
]);

const state = reactive({
  isCheckoutModalActive: false,
  closeModalIcon: "close-modal.svg",
  trashIcon: "remove-item-trash-blue.svg",
});

const reversedCartItems = computed(() => {
  return cartStore.cart.items.slice().reverse();
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/common", false, /\.svg$/);
  return images("./" + img);
};

const slideYUpTransition = () => {
  let delay = 100;

  setTimeout(() => {
    document.querySelector(".slide-up").classList.add("active");
  }, delay);
};

const closeCartModal = () => {
  emit("close-cart-modal");
};

const openCleanSaleModal = () => {
  emit("open-clean-sale-modal");
};

const openCheckoutAndCloseCartModal = () => {
  emit("open-checkout-and-close-cart-modal");
};

onMounted(() => {
  slideYUpTransition();
});
</script>

<template>
  <div id="cart-modal-page">
    <div class="cart-external-modal" @click="closeCartModal"></div>
    <div class="cart-modal-content slide-up">
      <div class="div-header">
        <div class="cart-elements">
          <p class="text-cart">Sacola</p>
          <span class="circle-item-quantity">
            {{ cartStore.getTotalItemsQuantity }}</span
          >
        </div>
        <span class="close-modal-container">
          <img
            @click="openCleanSaleModal"
            class="close-modal-sign"
            :src="getImgUrl(state.closeModalIcon)"
        /></span>
      </div>

      <div class="items-container">
        <cart-item
          @close-cart-modal="closeCartModal"
          @open-clean-sale-modal="openCleanSaleModal"
          v-for="item in reversedCartItems"
          :key="item.productId"
          :itemQuantity="item.quantity"
          :itemName="item.name"
          :itemPrice="item.price"
          :itemImageUrl="item.imageURL"
          :itemId="item.productId"
          :itemBarcode="item.barcode"
        />
      </div>

      <div class="cart-footer">
        <div class="div-hr-line">
          <div class="hr-line"></div>
        </div>
        <div class="div-subtotal">
          <p class="text-subtotal-title">Subtotal</p>
          <p class="text-subtotal">
            R$
            {{
              Number(cartStore.getTotalValue / 100)
                .toFixed(2)
                .toString()
                .replace(".", ",")
            }}
          </p>
        </div>
        <action-button
          @click="openCheckoutAndCloseCartModal"
          title="Continuar"
          width="43.75rem"
          height="7.5rem"
          marginTop="3.3rem"
        />

        <div class="div-empty-cart">
          <div @click="openCleanSaleModal" style="display: flex">
            <p class="text-empty-cart">Esvaziar Sacola</p>
            <img :src="getImgUrl(state.trashIcon)" class="icon-empty-cart" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cart-external-modal {
  width: 100vw;
  height: 13.5vh;
  z-index: 0;
}
.cart-modal-content {
  z-index: 1;
  width: 100%;
  height: 86.5rem;
  overflow: hidden;
  background-color: #fefefe;
  position: fixed;
  bottom: 0vh;
  border-radius: 2rem 2rem 0 0;
}
.slide-up {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.25s ease-in-out;
}
.slide-up.active {
  max-height: 100%;
}

.div-header {
  display: flex;
  width: 100vw;
  margin-top: 4.25rem;
  margin-bottom: 5.75rem;
}
.cart-elements {
  display: flex;
  align-items: center;
  width: 50vw;
}
.text-cart {
  color: #2e3191;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-left: 3rem;
  margin-right: 1.13rem;
}
.circle-item-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.0625rem;
  height: 4.0625rem;
  border-radius: 50%;
  background-color: #ec7000;
  color: #fff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.close-modal-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2.81rem;
  width: 50vw;
}
.close-modal-sign {
  width: 1.9375rem;
  height: 1.9375rem;
}
.div-empty-cart {
  display: flex;
  width: 100vw;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 5.25rem;
}
.text-empty-cart {
  color: #2e3191;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-right: 1.3rem;
}
.icon-empty-cart {
  width: 1.5625rem;
  height: 1.8125rem;
}
.items-container {
  max-height: 610px;
  overflow-y: scroll;
  margin-left: 3rem;
  padding-bottom: 5.5rem;
}
.items-container::-webkit-scrollbar {
  width: 0.75rem;
}
/* draggable scrolling handle */
.items-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
/* progress bar */
.items-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgba(217, 217, 217, 0.50);
}
.cart-footer {
  position: fixed;
  bottom: 0vh;
  background-color: white;
}
.div-hr-line {
  width: 100vw;
}
.hr-line {
  width: 90vw;
  margin: 0 auto;
  border-bottom: 1px solid #e6e6e6;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}
.div-subtotal {
  display: flex;
  width: 100vw;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 4rem;
}
.text-subtotal-title {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 6rem;
}
.text-subtotal {
  color: #ee7e18;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 6rem;
}
</style>
