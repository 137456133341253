<script setup>
import { reactive, defineEmits, onMounted } from "vue";

import { useUserStore } from "@/stores/user";
import { useLoadingStore } from "@/stores/loading";
import { useWarningStore } from "@/stores/warning";

const userStore = useUserStore();
const loadingStore = useLoadingStore();
const warningStore = useWarningStore();

const { isCPFValid, setPersonalId } = userStore;

const emit = defineEmits([
  "close-cpf-in-note-modal",
  "update-cpf-in-note-value",
]);

const state = reactive({
  clientCpf: "",
  itauLogoImg: "itau-logo-big.svg",
  clearInputIcon: "clear-input.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/common/", false, /\.svg$/);
  return images("./" + img);
};

const clearInput = () => {
  state.clientCpf = "";
};

const clearCpfErrorMessageIfTyping = () => {
  let cpfErrorMessage = document.getElementById("cpf-error-message");
  cpfErrorMessage.textContent = "";
};

const closeCpfInNoteModal = () => {
  emit("close-cpf-in-note-modal");
};

const updateCpfInNote = async () => {
  let cpfString = state.clientCpf.toString();

  if (cpfString != "") {
    cpfString = cpfString.padStart(11, "0"); // fix for cpf with leading zeros (ex: 07240411842, 00453760694)

    if (isCPFValid(cpfString)) {
      setPersonalId(cpfString);
      loadingStore.setLoading(true, "Adicionando Cliente");
      emit("update-cpf-in-note-value", cpfString);
      const promise = () => new Promise((resolve) => setTimeout(resolve, 1000));
      await promise();

      loadingStore.isLoading = false;
      warningStore.resetCounter();
      closeCpfInNoteModal();
    } else {
      let cpfErrorMessage = document.getElementById("cpf-error-message");
      cpfErrorMessage.textContent = "CPF INVÁLIDO";
    }
  }
};

onMounted(() => {
  let cpfInput = document.getElementById("input-cpf");
  cpfInput.focus();
});
</script>

<template>
  <div class="cpf-in-note-modal">
    <div class="cpf-in-note-modal-content">
      <img
        :src="getImgUrl(state.itauLogoImg)"
        alt="itau-logo"
        class="itau-logo"
      />
      <div class="cpf-in-note-rectangle">
        <p class="text-client-id">Identificação do Cliente</p>
        <p class="text-cpf">CPF</p>

        <div>
          <input
            type="number"
            maxlength="11"
            id="input-cpf"
            placeholder="000.000.000-00"
            v-model="state.clientCpf"
            required
            @input="clearCpfErrorMessageIfTyping"
          />
          <span @click="clearInput" class="clear-input-icon">
            <img :src="getImgUrl(state.clearInputIcon)" />
          </span>
        </div>

        <p id="cpf-error-message" class="cpf-error-message"></p>

        <div class="div-button">
          <button
            class="btn-confirm"
            @click="updateCpfInNote"
            :disabled="state.clientCpf === null"
          >
            Confirmar
          </button>
        </div>

        <p @click="closeCpfInNoteModal" class="close-modal">Fechar</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
input {
  width: 90%;
  display: block;
  margin: 0 auto;
  padding-bottom: 0.44rem;
  border-color: #949494;
  border-width: 0 0 1px;
  outline: 0;
  color: black;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
input::placeholder {
  color: #949494;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cpf-in-note-modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  background-color: #ec7000;
}
.cpf-in-note-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.itau-logo {
  margin-top: 9.5rem;
  margin-bottom: 7.75rem;
}
.cpf-in-note-rectangle {
  display: flex;
  flex-direction: column;
  width: 47.5rem;
  height: 46.375rem;
  border-radius: 1.25rem;
  background: #fff;
}
.text-client-id {
  color: #656262;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  margin-top: 5.69rem;
  margin-bottom: 6.69rem;
}
.text-cpf {
  color: #656262;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-left: 2.35rem;
  margin-bottom: 1.75rem;
}
.cpf-error-message {
  color: red;
  font-size: 2rem;
  text-align: center;
  margin-top: 0.75rem;
}
.clear-input-icon {
  position: absolute;
  top: -0.1rem;
  right: 3.5rem;
}
.div-button {
  display: flex;
  justify-content: center;
  margin-top: 6.69rem;
  margin-bottom: 2.2rem;
}
.btn-confirm {
  width: 37.5rem;
  height: 7.5rem;
  border-radius: 0.625rem;
  background: #ec7000;
  color: #fff;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: unset;
}
.btn-confirm:disabled {
  background: #d7d7d7;
  color: #000;
}
.close-modal {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
</style>
