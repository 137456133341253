import { defineStore } from "pinia";

export const useCommonStore = defineStore("common", {
  state: () => ({
    device: null,
    deviceId: "",
    socketToken: "",
  }),

  getters: {
    getDeviceId(state) {
      return state.deviceId;
    },

    getSocketToken(state) {
      return state.socketToken;
    }
  },

  actions: {
    setDeviceInfo(device) {
      this.device = device;
    },

    setDeviceId(deviceId) {
      this.deviceId = deviceId;
    },

    setSocketToken(token) {
      this.socketToken = token;
    },
  },
});
