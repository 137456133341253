import { defineStore } from "pinia";

export const useBarcodeReaderStore = defineStore("barcodereader", {
  state: () => ({
    barcode: "",
    canReadBarcode: false,
    productFound: false,
  }),

  actions: {
    setBarcode(barcode) {
      this.barcode = barcode;
    },

    setCanReadBarcode(canReadBarcode) {
      this.canReadBarcode = canReadBarcode;
    },

    setProductFound(productFound) {
      this.productFound = productFound;
    },
  },
});
