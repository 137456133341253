<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
</script>

<template>
  <div @click="router.replace('barcode-reading')" class="div-square">
    <div class="div-text">Comprar agora</div>
  </div>
</template>

<style scoped>
.div-square {
  background-color: transparent;
  border: 6px solid white;
  border-radius: 5rem;
  width: 47.25rem;
  height: 54.3125rem;
  margin: auto;
  margin-top: 3rem;
}
.div-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 92%;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  font-size: 4rem;
  font-weight: 700;
  color: #ec7000;
  text-align: center;
  background-color: white;
  width: 33.375rem;
  height: 8.9375rem;
  border-radius: 1rem;
}
</style>
