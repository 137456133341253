<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const state = reactive({
  iconInfoPath: "icon-info-exclamation.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/login", false, /\.svg$/);
  return images("./" + img);
};
</script>

<template>
  <div class="login-error-page">
    <img :src="getImgUrl(state.iconInfoPath)" class="icon-info" />
    <p class="text-login-error">Erro no login:</p>
    <p class="text-error-message">
      Tente repetir a operação. Caso o problema seja recorrente,<br />
      abra um chamado incluindo uma fotografia da tela.
    </p>

    <div>
      <p class="text-error-title">Tipo de erro:</p>
      <p class="text-error-info" style="margin-bottom: 2.5rem">
        Usuário ou senha inválidos
      </p>

      <p class="text-error-title">Código do erro:</p>
      <p class="text-error-info">BADSTOQ-STT-20240104-135139</p>

      <button @click="router.replace('/')" class="btn-action">Entendi</button>
    </div>
  </div>
</template>

<style scoped>
.login-error-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-info {
  width: 4.375rem;
  height: 4.375rem;
  margin-bottom: 0.5rem;
}
.text-login-error {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.text-error-message {
  color: #949494;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 2.62rem auto 5.5rem auto;
}
.text-error-title {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.5rem;
}
.text-error-info {
  color: #949494;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.btn-action {
  width: 48.625rem;
  height: 3.8125rem;
  margin-top: 8.3rem;
  border-radius: 0.625rem;
  background: #ec7000;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
