import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";

export const useErrorStore = defineStore("error", {
  state: () => ({
    isErrorModalOpen: false,
    message: "",
    title: "errorTitle",
    width: 750,
    t: useI18n().t,
    hasSalesErrorOcurred: false,
    salesErrorCode: null,
    salesErrorMessage: "",
  }),

  getters: {
    getSalesErrorOccurence(state) {
      return state.hasSalesErrorOcurred;
    },
    getSalesErrorCode(state) {
      return state.salesErrorCode;
    },
    getSalesErrorMessage(state) {
      return state.salesErrorMessage;
    },
  },

  actions: {
    alertError(data) {
      this.message = this.t(`error.${data.message}`);
      this.title = data.title
        ? this.t(`error.${data.title}`)
        : this.t(`error.errorTitle`);
      this.width = data.width || 640;
      this.isErrorModalOpen = true;
    },
    setSalesErrorOccurence(hasSalesErrorOcurred) {
      this.hasSalesErrorOcurred = hasSalesErrorOcurred;
    },
    setSalesErrorInfo(code, msg) {
      this.salesErrorCode = code;
      this.salesErrorMessage = msg;
    },
    clearSalesErrorInfo() {
      this.salesErrorCode = null;
      this.salesErrorMessage = "";
    },
  },
});
