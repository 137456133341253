import { createI18n } from 'vue-i18n'

import pt_BR from "./locales/pt_BR";
import en from "./locales/en";

const messages = {
  pt_BR,
  en
};

const i18n = createI18n({
  legacy: false,
  locale: navigator.language.replace("-", "_"),
  fallbackLocale: {
    default: ["pt_BR", "en"]
  },
  messages
})

export default i18n