import { defineStore } from "pinia";
import $http from "../axios";

import { useCommonStore } from "@/stores/common";
import { useCartStore } from "@/stores/cart";
import { useCardStore } from "@/stores/card";

export const useProductStore = defineStore("product", {
  state: () => ({
    products: [],
    scaleProducts: [],
    keepCatalogId: false,
    tryFillOneLeftZero: false,
    tryFillTwoLeftZeros: false,
    temporaryProduct: null,
  }),

  actions: {
    getProducts(storeId) {
      return new Promise((resolve, reject) => {
        $http
          .get(`products`, {
            params: {
              storeId,
              longLat: "-47.904122,-21.995002",
            },
          })
          .then(async (res) => {
            if (res.data.result === "ok") {
              // SET PRODUCTS
              let products = res.data.payload.products;
              // prevent duplicated itens in catalog to break searchbar (and possibly other stuff)
              const index = {};
              const nonDuplicatedProducts = [];
              for (let i = 0; i < products.length; i++) {
                if (!index.hasOwnProperty(products[i]._id)) {
                  index[products[i]._id] = i;
                  nonDuplicatedProducts.push(products[i]);
                } else {
                  console.log("PRODUCT_ID: " + products[i]._id);
                }
              }
              this.products = nonDuplicatedProducts;

              // SET SCALE PRODUCTS
              let scaleProductsBase = res.data.payload.scaleProductsBase;
              let scaleProducts = res.data.payload.scaleProducts;
              const scaleProductsIndexes = [];
              for (let i = 0; i < scaleProductsBase.length; i++) {
                scaleProductsIndexes[scaleProductsBase[i].code] = i;
              }
              for (let i = 0; i < scaleProducts.length; i++) {
                if (
                  scaleProductsIndexes.hasOwnProperty(scaleProducts[i].code)
                ) {
                  scaleProducts[i] = {
                    ...scaleProducts[i],
                    ...scaleProductsBase[
                      scaleProductsIndexes[scaleProducts[i].code]
                    ],
                  };
                } else {
                  scaleProducts.splice(i, 1);
                  i--;
                }
              }
              this.scaleProducts = scaleProducts;

              // CATALOG ID
              const cartStore = useCartStore();
              let catalogId = res.data.payload.catalogId;
              cartStore.setCatalogId(catalogId);

              // SET FILL LEFT ZEROS
              this.tryFillOneLeftZero = res.data.payload.fillLeftZeros.one;
              this.tryFillTwoLeftZeros = res.data.payload.fillLeftZeros.two;

              // SET_NO_ALCOHOLICS_CARD_BRANDS
              const cardStore = useCardStore();
              cardStore.setNoAlcoholicsCardBrands(
                res.data.payload.noAlcoholicsCardBrands
              );

              // SET NEEDS CVV
              cardStore.setNeedsCVV(res.data.payload.needsCVV);

              // SET SOCKET TOKEN
              const commonStore = useCommonStore();
              if (res.data.payload.socketToken) {
                await commonStore.setSocketToken(res.data.payload.socketToken);
              }
            }
            resolve(res);
            // return res;
          })
          .catch((e) => {
            // return e;
            reject(e);
          });
      });
    },

    searchProductOtherStores(data) {
      return new Promise((resolve, reject) => {
        $http
          .post(`search-product-other-stores`, data)
          .then((res) => {
            if (res.data.result === "ok" && res.data.payload.product) {
              // Add Missing Product to Catalog
              this.products.push(res.data.payload.product);
            }
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
});
