<script setup>
import { reactive, onMounted, onBeforeUnmount, watch } from "vue";
import { storeToRefs } from "pinia";

import { useRouter } from "vue-router";

import { clisitef } from "onii-clisitef";

import WelcomeHeader from "@/components/welcome/WelcomeHeader.vue";
import WelcomeText from "@/components/welcome/WelcomeText.vue";
import SquareAndText from "@/components/welcome/SquareAndText.vue";

import { useBarcodeReaderStore } from "@/stores/barcode";
import { useUserStore } from "@/stores/user";
import { useCartStore } from "@/stores/cart";
import { useWarningStore } from "@/stores/warning";
import { useCheckoutStore } from "@/stores/checkout";
import { useTotemStore } from "@/stores/totem";
import { useCommonStore } from "@/stores/common";
import { useLoginStore } from "@/stores/login";
import { useLoadingStore } from "@/stores/loading";
import { useErrorStore } from "@/stores/error";

const router = useRouter();

const totem = useTotemStore();
const barcodeReader = useBarcodeReaderStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const warningStore = useWarningStore();
const loadingStore = useLoadingStore();
const errorStore = useErrorStore();

const { barcode } = storeToRefs(barcodeReader);

const { setCanReadBarcode } = barcodeReader;
const { clearPersonalId } = userStore;
const { emptyCart, emptyCatalog } = cartStore;
const { emptyTotemInfo } = totem;
const { clearSalesErrorInfo } = errorStore;

const state = reactive({
  interval: null,
  counter: 0,
});

watch(barcode, async (newBarcode, oldBarcode) => {
  if (newBarcode && newBarcode !== "") {
    barcodeReader.setBarcode("");
    if (barcodeReader.productFound) {
      router.replace("/barcode-reading");
    }
  }
});

const cancelarPendente = async (tefTerminal, transactionId) => {
  if (tefTerminal) {
    console.log(
      "Iniciando processo de cancelamento de Compra Pendente.",
      tefTerminal ? JSON.stringify(tefTerminal, null, "\t") : ""
    );

    let tefLogs = [];

    const config = {
      ip: "127.0.0.1",
      empresa: useTotemStore().payments.terminal.sitef.storeId,
      terminal: useTotemStore().payments.terminal.sitef.serialNumber,
      parametros: `[ParmsClient=1=${String(
        useTotemStore().nationalCompanyId
      ).replace(
        /\D/g,
        ""
      )};2=36163718000146];[TipoPinPad=ANDROID_USB;TipoComunicacaoExterna=GSURF.SSL;GSurf.OTP=${
        useTotemStore().payments.terminal.gsurf.otp
      };TerminalUUID=${useTotemStore().payments.terminal.gsurf.uuid}]`,
    };

    const cancelData = {
      cupomFiscal: tefTerminal.sale.cupomFiscal,
      data: tefTerminal.sale.data,
      hora: tefTerminal.sale.hora,
    };

    let pinpadTimer = setTimeout(async () => {
      console.log("PINPAD TIMEOUT");
      await clisitef.cancelar();
    }, 30000);

    clisitef.addListener("onUpdate", async (info) => {
      console.log("onUpdate was fired", JSON.stringify(info));

      const si = info.message.indexOf("]");

      if (
        info.message.toLowerCase().indexOf("comando recebido:") == -1 &&
        info.message.toLowerCase().indexOf("comando enviando:") == -1
      ) {
        loadingStore.setMessage(
          si > -1 ? info.message.substring(si + 1).trim() : info.message
        );
      }

      tefLogs.push(info.message);
      info.log = tefLogs;
      console.log("Resposta: ", info.log);

      info.cupomCliente =
        tefTerminal.response && tefTerminal.response.cupomCliente
          ? tefTerminal.response.cupomCliente
          : "";

      info.cupomEmpresa =
        tefTerminal.response && tefTerminal.response.cupomEmpresa
          ? tefTerminal.response.cupomEmpresa
          : "";

      if (info.success) {
        info.success = false;
        info.cancel = true;
      }

      await useCheckoutStore().updateTefCheckoutAction({
        transactionId: transactionId,
        tefResponse: info,
        tefConfig: config,
        tefSale: cancelData,
      });
    });

    await clisitef
      .cancelarPendente({
        config,
        cupom: cancelData,
      })
      .then((resp) => {
        console.log("Clisitef OK: ", JSON.stringify(resp, null, "\t"));
        clearTimeout(pinpadTimer);
      })
      .catch((err) => {
        console.log("Clisitef ERROR: ", JSON.stringify(err, null, "\t"));
        clearTimeout(pinpadTimer);
      });

    clisitef.removeAllListeners();
  }
};

onMounted(async () => {
  setCanReadBarcode(true);
  warningStore.clearCounter();
  emptyCart();
  emptyCatalog();
  emptyTotemInfo();
  clearPersonalId();
  clearSalesErrorInfo();

  // Load totem info
  if (
    useTotemStore().getTotemPayments === null &&
    useTotemStore().getTotemNationalCompanyId === ""
  ) {
    await useTotemStore().getTotemInfo({
      deviceId: useCommonStore().getDeviceId,
    });
  }

  // Check if there is a pending transaction
  // FIXME: em modo dev esse trecho abaixo está dando problema ao chamar rota get-tef-transaction
  if (
    process.env.NODE_ENV === "production" &&
    useLoginStore().getSessionToken &&
    useLoginStore().getIsAuthenticated &&
    useTotemStore().getTotemPayments.terminal.provider === "gsurf"
  ) {
    await useCheckoutStore()
      .checkTransaction()
      .then((resp) => {
        console.log(
          "PENDING_TRANSACTION: ",
          JSON.stringify(resp.data.payload, null, "\t")
        );
        if (resp.data.payload.transaction) {
          cancelarPendente(
            resp.data.payload.transaction.tefTerminal,
            resp.data.payload.transaction._id
          );
        }
      });
  }

  clearInterval(state.interval);

  state.interval = setInterval(() => {
    let element = null;

    switch (state.counter) {
      case 0:
        element = document.getElementsByClassName("welcome-page")[0];
        if (element) element.classList.remove("background-image-celular");
        else clearInterval(state.interval);

        element = document.getElementsByClassName("welcome-page")[0];
        if (element) element.classList.add("background-image-secador");
        else clearInterval(state.interval);
        break;

      case 1:
        element = document.getElementsByClassName("welcome-page")[0];
        if (element) element.classList.remove("background-image-secador");
        else clearInterval(state.interval);

        element = document.getElementsByClassName("welcome-page")[0];
        if (element) element.classList.add("background-image-tenis");
        else clearInterval(state.interval);
        break;

      case 2:
        element = document.getElementsByClassName("welcome-page")[0];
        if (element) element.classList.remove("background-image-tenis");
        else clearInterval(state.interval);

        element = document.getElementsByClassName("welcome-page")[0];
        if (element) element.classList.add("background-image-feliz");
        else clearInterval(state.interval);
        break;

      case 3:
        element = document.getElementsByClassName("welcome-page")[0];
        if (element) element.classList.remove("background-image-feliz");
        else clearInterval(state.interval);

        element = document.getElementsByClassName("welcome-page")[0];
        if (element) element.classList.add("background-image-celular");
        else clearInterval(state.interval);
        break;
    }

    state.counter++;

    if (state.counter === 4) state.counter = 0;
  }, 6000);
});

onBeforeUnmount(() => {
  clearInterval(state.interval);
  setCanReadBarcode(false);
});
</script>

<template>
  <div class="welcome-page background-image-celular">
    <welcome-header />

    <welcome-text />

    <square-and-text />
  </div>
</template>

<style scoped>
.welcome-page {
  height: 100vh;
  width: 100vw;
  background-color: rgb(236, 111, 46);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;
  transition: background-image 3s;
}
.background-image-celular {
  background-image: url("../assets/img/welcome/modelo-celular-no-bg.png");
}
.background-image-secador {
  background-image: url("../assets/img/welcome/modelo-secador-no-bg.png");
}
.background-image-tenis {
  background-image: url("../assets/img/welcome/modelo-tenis-no-bg.png");
}
.background-image-feliz {
  background-image: url("../assets/img/welcome/modelo-feliz-no-bg.png");
}
</style>
