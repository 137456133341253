<script setup>
import { reactive, defineEmits } from "vue";

import { useCartStore } from "@/stores/cart";
import { useLoadingStore } from "@/stores/loading";

const cartStore = useCartStore();
const loadingStore = useLoadingStore();

const { emptyCart } = cartStore;

const emit = defineEmits(["close-clean-sale-modal"]);

const state = reactive({
  itauLogoImg: "itau-logo-big.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/common", false, /\.svg$/);
  return images("./" + img);
};

const closeCleanSaleModal = () => {
  emit("close-clean-sale-modal");
};

const emptyCartAndCloseModal = async () => {
  emptyCart();
  loadingStore.setLoading(true, "Esvaziando Sacola");
  const promise = () => new Promise((resolve) => setTimeout(resolve, 1000));
  await promise();
  loadingStore.isLoading = false;
  closeCleanSaleModal();
};
</script>

<template>
  <div class="error-modal-modal">
    <div class="error-modal-modal-content">
      <img
        :src="getImgUrl(state.itauLogoImg)"
        alt="itau-logo"
        class="itau-logo"
      />
      <div class="error-modal-rectangle">
        <p class="text-error-title">Deseja limpar a venda?</p>
        <p class="text-error-content">
          Se algum pagamento foi realizado nesta venda, você precisa estorná-los
          manualmente.
        </p>

        <div class="div-button">
          <button class="btn-return" @click="emptyCartAndCloseModal">
            Limpar a venda!
          </button>
        </div>

        <p class="text-go-back" @click="closeCleanSaleModal">
          Continuar comprando
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error-modal-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  background-color: #ec7000;
}
.error-modal-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.itau-logo {
  margin-top: 9.5rem;
  margin-bottom: 7.75rem;
}
.error-modal-rectangle {
  display: flex;
  flex-direction: column;
  width: 47.5rem;
  height: 46.375rem;
  border-radius: 1.25rem;
  background: #fff;
}
.text-error-title {
  text-align: center;
  color: #656262;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 8.88rem;
  margin-bottom: 2rem;
}
.text-error-content {
  color: #656262;
  text-align: center;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 8.5rem;
}
.div-button {
  display: flex;
  justify-content: center;
}
.btn-return {
  width: 37.5rem;
  height: 7.5rem;
  border-radius: 0.625rem;
  background: #e76f70;
  color: #fff;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: unset;
}
.text-go-back {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-top: 3rem;
}
</style>
