import { defineStore } from "pinia";
import $http from "../axios";

export const useTotemStore = defineStore("totem", {
  state: () => ({
    // totemStoreId: "62b209b57fd9dc3b8556eb77", // Sede Onii
    // totemStoreId: "5e752327788f2a2e74612011", // Onii Village I
    totemStoreId: null,
    totemManufacturer: "",
    totemModel: "",
    payments: null,
    nationalCompanyId: "",
  }),

  persist: true,

  getters: {
    getTotemStoreId(state) {
      return state.totemStoreId;
    },

    getTotemPayments(state) {
      return state.payments;
    },

    getTotemNationalCompanyId(state) {
      return state.nationalCompanyId;
    },
  },

  actions: {
    setTotemStoreId(totemStoreId) {
      this.totemStoreId = totemStoreId;
    },

    getTotemInfo(deviceId) {
      return new Promise((resolve, reject) => {
        $http
          .get("get-totem-info", { params: deviceId })
          .then((res) => {
            if (res.data.result === "ok") {
              this.payments = res.data.payload.payments;
              this.nationalCompanyId = res.data.payload.nationalCompanyId;
            }
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    emptyTotemInfo() {
      this.payments = null;
      this.nationalCompanyId = "";
    },
  },
});
