<script setup>
import { reactive, onUpdated, defineEmits, computed } from "vue";

import { useRouter } from "vue-router";

import { clisitef } from "onii-clisitef";

import moment from "moment";

import PaymentMethodCards from "@/components/checkout-modal/PaymentMethodCards.vue";
import CpfInNoteModal from "@/components/cpf-in-note-modal/CPFInNoteModal.vue";
import PaymentInstallments from "@/components/checkout-modal/PaymentInstallments.vue";
import PixModal from "@/components/pix-modal/PixModal.vue";

import { useTotemStore } from "@/stores/totem";
import { useErrorStore } from "@/stores/error";
import { useCommonStore } from "@/stores/common";
import { useCheckoutStore } from "@/stores/checkout";
import { useCartStore } from "@/stores/cart";
import { useUserStore } from "@/stores/user";
import { useLoadingStore } from "@/stores/loading";
import { useWarningStore } from "@/stores/warning";

const router = useRouter();

const commonStore = useCommonStore();
const checkoutStore = useCheckoutStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const loadingStore = useLoadingStore();
const warningStore = useWarningStore();

const { createTefCheckoutAction, updateTefCheckoutAction } = checkoutStore;

const { clearPersonalId } = userStore;

const emit = defineEmits([
  "close-checkout-open-cart-modal",
  "close-checkout-and-cart-modal",
]);

const state = reactive({
  goBackArrowIcon: "go-back-arrow.svg",
  closeModalIcon: "close-modal.svg",
  cpfIcon: "cpf-icon.svg",
  paymentMethod: "",
  paymentInstallments: 1,
  isCpfInNoteSelected: false,
  isCPFInNoteModalActive: false,
  isPixModalActive: false,
  clientCpf: "",

  timer: 0,
  maxTime: 55,
  intervalId: null,

  terminalPaymentInProgress: false,

  tefTransaction: null,
  tefTransactionSuccess: false,

  isTef: false,
});

const installmentAmount = computed(() => {
  // subtotal ?
  return Number(cartStore.getTotalValue / state.paymentInstallments).toFixed(2);
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/common", false, /\.svg$/);
  return images("./" + img);
};

const returnPaymentMethod = (paymentMethod) => {
  state.paymentMethod = paymentMethod;
};

const updatePaymentInstallments = (paymentInstallments) => {
  state.paymentInstallments = paymentInstallments;
};

const clearClientCpf = () => {
  state.clientCpf = "";
  clearPersonalId();
};

const updateCpfInNoteOption = () => {
  // se já tem cpf na nota e o usuario quiser desabilitar
  if (state.clientCpf !== "") {
    state.isCpfInNoteSelected = false;
    state.isCPFInNoteModalActive = false;

    if (state.isCpfInNoteSelected === false) {
      clearClientCpf();
    }
  } else {
    state.isCpfInNoteSelected = !state.isCpfInNoteSelected;
    state.isCPFInNoteModalActive = true;
  }
};

const updateCpfInNoteValue = (cpfCpnj) => {
  state.clientCpf = cpfCpnj;
};

const closeCheckoutOpenCartModal = () => {
  emit("close-checkout-open-cart-modal");
};

const closeCheckoutAndCartModal = () => {
  emit("close-checkout-and-cart-modal");
};

const closeCpfInNoteModal = () => {
  state.isCPFInNoteModalActive = false;
};

const closePixModal = () => {
  state.isPixModalActive = false;
};

const checkPaymentTypeAndCheckout = async () => {
  // Debit or Credit
  if (state.paymentMethod && state.paymentMethod !== "pix") {
    warningStore.clearCounter();

    await createTefCheckout(state.paymentMethod);

    // loadingStore.setLoading(true, "Servidor Conectado");
    // const promise = () => new Promise((resolve) => setTimeout(resolve, 1000));
    // await promise();
    // loadingStore.setMessage("Aproxime, insira ou passe o cartão na leitora");
    // await promise();
    // loadingStore.setMessage(
    //   `Método selecionado: ${
    //     state.paymentMethod === "debit" ? "Débito" : "Crédito"
    //   }`
    // );
    // await promise();
    // loadingStore.setMessage("Aguarde, em processamento...");
    // await promise();
    // loadingStore.setMessage("Transação Aprovada");
    // await promise();
    // loadingStore.setMessage("Conectando ao Servidor");
    // await promise();
    // loadingStore.setMessage("Confirmando Venda");
    // await promise();
    // loadingStore.setMessage("Imprimindo o cupom da venda");
    // await promise();

    // loadingStore.isLoading = false;
  } // PIX
  else if (state.paymentMethod && state.paymentMethod === "pix") {
    state.isPixModalActive = true;
  }
};

const createTefCheckout = async (fundingSource) => {
  let success = false;

  try {
    loadingStore.setLoading(true, "Iniciando processo de pagamento");
    state.isTef = true;

    cartStore.cart.totalValue = cartStore.getTotalValue;

    if (cartStore.cart.items.length < 1) {
      useErrorStore().alertError("CART_CANNOT_BE_EMPTY");
      return false;
    }

    const res = await createTefCheckoutAction({
      storeId: useTotemStore().getTotemStoreId,
      longLat: [-33.34242, -34.4324],
      cart: cartStore.cart,
      installments: state.paymentInstallments,
      fundingSource,
    });

    console.log("APÓS CREATE_TEF_CHECKOUT_ACTION");

    if (!res || !res.data || res.data.result === "error") {
      useErrorStore().alertError(res.data.message);
      return;
    }

    state.tefTransaction = res.data.payload.transaction;

    // console.log(
    //   "tefTransaction: ",
    //   JSON.stringify(state.tefTransaction, null, "\t")
    // );

    // console.log(
    //   "payments: ",
    //   JSON.stringify(useTotemStore().payments, null, "\t")
    // );

    const config = {
      ip: "127.0.0.1",
      empresa: useTotemStore().payments.terminal.sitef.storeId,
      terminal: useTotemStore().payments.terminal.sitef.serialNumber,
      parametros: `[ParmsClient=1=${String(
        useTotemStore().nationalCompanyId
      ).replace(
        /\D/g,
        ""
      )};2=36163718000146];[TipoPinPad=ANDROID_USB;TipoComunicacaoExterna=GSURF.SSL;GSurf.OTP=${
        useTotemStore().payments.terminal.gsurf.otp
      };TerminalUUID=${useTotemStore().payments.terminal.gsurf.uuid}]`,
    };

    const saleData = {
      valor: state.tefTransaction.cart.totalValue / 100,
      cupomFiscal: res.data.payload.cupom,
      data: moment().format("YYYYMMDD"),
      hora: moment().format("HHmmss"),
      restricoes: "",
      parcelas: state.paymentInstallments,
      metodoPagamento:
        fundingSource === "debit"
          ? ["debito", "débito"]
          : ["credito", "crédito"],
    };

    state.terminalPaymentInProgress = true;

    state.tefTransactionSuccess = false;
    if (state.intervalId) {
      try {
        clearInterval(state.intervalId);
      } catch (ex) {
        console.log("Erro em if intervalId", JSON.stringify(ex, null, "\t"));
      }
    }

    state.timer = 0;

    let tefLogs = [];

    console.log("Antes do Listener");

    let pinpadTimer = setTimeout(async () => {
      await clisitef.cancelar();
    }, 30000);

    clisitef.addListener("onPinpadConnected", (info) => {
      clearTimeout(pinpadTimer);
    });

    clisitef.addListener("onUpdate", async (info) => {
      console.log("onUpdate was fired", JSON.stringify(info));

      const si = info.message.indexOf("]");

      if (
        info.message.toLowerCase().indexOf("comando recebido:") == -1 &&
        info.message.toLowerCase().indexOf("comando enviando:") == -1
      ) {
        loadingStore.setMessage(
          si > -1 ? info.message.substring(si + 1).trim() : info.message
        );
      }

      state.timer = 0;
      tefLogs.push(info.message);
      info.log = tefLogs;
      console.log("Resposta: ", info.log);

      await useCheckoutStore().updateTefCheckoutAction({
        transactionId: state.tefTransaction._id,
        tefResponse: info,
        tefConfig: config,
        tefSale: saleData,
      });

      if (info) {
        if (info.success) {
          state.tefTransactionSuccess = true;
          state.terminalPaymentInProgress = false;
          state.isTef = false;
          try {
            clearInterval(state.intervalId);
          } catch (ex) {
            console.log("Erro em if info.success", JSON.stringify(ex, null, "\t"));
          }
        } else if (info.cancel) {
          state.timer = state.maxTime - 3;
          setTimeout(() => {
            abortTefCheckout();
          }, 3000);
        }
      }
    });

    await clisitef
      .vende({
        config,
        values: saleData,
      })
      .then(async (resp) => {
        success = resp.success;
        console.log("Clisitef OK: ", JSON.stringify(resp, null, "\t"));

        const numbersRegex = /-?\d+/;
        const errorCode = Number(resp.message.match(numbersRegex));

        const numbersAndBracketsRegex = /(\[-?\d+\])/;
        const errorMessage = resp.message
          .replace(numbersAndBracketsRegex, "")
          .trim();

        if (
          ((errorCode != 0 && errorCode != 10000 && errorCode > 0) ||
            errorCode < 0) &&
          resp.cancel
        ) {
          useErrorStore().setSalesErrorInfo(errorCode, errorMessage);
        }

        if (process.env.NODE_ENV === "development") {
          success = true;

          await useCheckoutStore().updateTefCheckoutAction({
            transactionId: state.tefTransaction._id,
            tefResponse: {
              cancel: false,
              success: true,
              message: "Transação Aprovada MODO_DEV",
              cupomCliente: "",
              cupomEmpresa: "",
            },
            tefConfig: config,
            tefSale: saleData,
          });
        }
      })
      .catch((err) => {
        console.log("Clisitef ERROR: ", JSON.stringify(err, null, "\t"));
      });
  } catch (err) {
    console.log("Erro: ", JSON.stringify(err, null, "\t"));
    useErrorStore().alertError("UNEXPECTED_ERROR");
  } finally {
    clisitef.removeAllListeners();

    if (success) {
      router.replace("/transaction-finished");
    }
    // else if (
    //   success === undefined &&
    //   process.env.NODE_ENV === "development"
    // ) {
    //   router.replace("/transaction-finished");
    // }
    else {
      router.replace("/sales-error");
    }

    loadingStore.setLoading(false);
  }
};

const abortTefCheckout = () => {
  try {
    clearInterval(state.intervalId);
  } catch (ex) {
    console.log("Erro em abortTefCheckout", JSON.stringify(ex, null, "\t"));
  }

  if (!state.tefTransactionSuccess) {
    loadingStore.isLoading = false;
    state.terminalPaymentInProgress = false;
    //clisitef.cancelar();
  }
};

onUpdated(() => {
  // Se o usuário já incluiu cpf e for válido
  if (state.clientCpf !== "") {
    state.isCpfInNoteSelected = true;
    state.isCPFInNoteModalActive = false;
  }
  // Se o usuario já incluiu cpf e a opção ainda estiver selecionada
  if (state.clientCpf === "" && state.isCpfInNoteSelected === true) {
    state.isCpfInNoteSelected = false;
  }
});
</script>

<template>
  <div id="checkout-page" class="checkout-page">
    <div class="div-top-container">
      <div
        @click="closeCheckoutOpenCartModal"
        class="div-container-continue-purchase"
      >
        <img
          :src="getImgUrl(state.goBackArrowIcon)"
          class="img-go-back-arrow"
        />
        <p class="continue-purchase-text">Continuar comprando</p>
      </div>
    </div>

    <div class="div-container-close-modal">
      <div class="div-container-left-text">
        <p class="pay-with-itau-text">pagar com Itaú</p>
      </div>
    </div>

    <div class="div-container-value-to-pay">
      <div class="div-container-left-text">
        <p class="text-value-to-pay">valor a pagar</p>
      </div>
      <div class="div-container-right-text">
        <p class="text-checkout-value">
          R$
          {{
            Number(cartStore.getTotalValue / 100)
              .toFixed(2)
              .toString()
              .replace(".", ",")
          }}
        </p>
      </div>
    </div>

    <div class="div-hr-line">
      <div class="hr-line"></div>
    </div>

    <p class="text-payment-method">método de pagamento</p>

    <payment-method-cards @payment-method-selection="returnPaymentMethod" />

    <div class="cpf-container">
      <img :src="getImgUrl(state.cpfIcon)" />
      <p class="text-cpf-in-note">
        {{
          state.clientCpf !== ""
            ? `CPF: ${state.clientCpf.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4"
              )}`
            : "Incluir CPF na nota?"
        }}
      </p>
      <div style="display: flex">
        <p class="text-option">
          {{ state.isCpfInNoteSelected ? "Sim" : "Não" }}
        </p>
        <o-switch
          size="large"
          variant="primary"
          v-model="state.isCpfInNoteSelected"
          @input="updateCpfInNoteOption"
        ></o-switch>
      </div>
    </div>

    <payment-installments
      v-if="state.paymentMethod === 'credit'"
      @update-payment-installments="updatePaymentInstallments"
    />

    <div class="cart-footer">
      <div class="div-hr-line">
        <div class="hr-line"></div>
      </div>
      <div class="div-subtotal">
        <p class="text-subtotal-title">subtotal</p>
        <p class="text-subtotal">
          R$
          {{
            Number(cartStore.getTotalValue / 100)
              .toFixed(2)
              .toString()
              .replace(".", ",")
          }}
        </p>
      </div>
      <div class="div-button-continue">
        <button
          @click="checkPaymentTypeAndCheckout"
          :disabled="state.paymentMethod === ''"
          class="btn-continue"
        >
          Finalizar pedido
        </button>
      </div>
    </div>

    <cpf-in-note-modal
      v-if="state.isCPFInNoteModalActive"
      @update-cpf-in-note-value="updateCpfInNoteValue"
      @close-cpf-in-note-modal="closeCpfInNoteModal"
    />

    <pix-modal v-if="state.isPixModalActive" @close-pix-modal="closePixModal" />
  </div>
</template>

<style scoped>
.checkout-page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: white;
}
.div-top-container {
  display: flex;
  align-items: center;
  width: 100vw;
  margin-top: 3.5rem;
}
.div-container-continue-purchase {
  display: flex;
  align-items: center;
  width: 50vw;
}
.div-container-close-modal {
  display: flex;
  width: 100vw;
  margin-top: 2.5rem;
}
.div-container-value-to-pay {
  display: flex;
  width: 100vw;
  margin-top: 4rem;
}
.div-container-left-text {
  display: flex;
  align-items: center;
  width: 52vw;
}
.div-container-right-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50vw;
}
.img-go-back-arrow {
  width: 1.125rem;
  height: 0.625rem;
  margin-left: 3.15rem;
  margin-right: 0.8rem;
}
.continue-purchase-text {
  color: #ec7000;
  font-size: 2.125rem;
  font-weight: 700;
}
.pay-with-itau-text {
  color: #000;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 3rem;
  margin-right: 0.56rem;
}
.close-modal-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2.81rem;
  width: 50vw;
}
.close-modal-sign {
  width: 1.9375rem;
  height: 1.9375rem;
}
.text-value-to-pay {
  color: #000;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 3rem;
}
.text-checkout-value {
  color: #ee7e18;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 2.81rem;
}
.cart-footer {
  position: fixed;
  bottom: 0vh;
  background-color: white;
  padding-bottom: 7.2rem;
}
.div-hr-line {
  width: 100vw;
  margin-top: 1rem;
}
.hr-line {
  width: 90vw;
  margin: 0 auto;
  border-bottom: 1px solid #949494;
}
.div-subtotal {
  display: flex;
  width: 100vw;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 1.25rem;
}
.text-subtotal-title {
  color: #000;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 3rem;
}
.text-subtotal {
  color: #ee7e18;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 3rem;
}
.div-button-continue {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-top: 4.2rem;
}
.btn-continue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52.125rem;
  height: 8.9375rem;
  border-radius: 1.25rem;
  background: #ec7000;
  border: unset;
  color: #fff;
  text-align: center;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.btn-continue:disabled {
  background: #d7d7d7;
}
.btn-continue:enabled {
  background: #ec7000;
}
.text-payment-method {
  color: #000;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2.3rem;
  margin-left: 3rem;
  margin-bottom: 3.94rem;
}

.cpf-container {
  display: flex;
  align-items: center;
  margin-top: 4rem;
  justify-content: space-around;
}
.text-cpf-in-note {
  left: -80px;
  color: #000;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-option {
  color: #000;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 0.8rem;
}
.switch {
  width: 5rem;
  height: 1.9375rem;
}
</style>
