<script setup>
import { reactive, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";

import { useBarcodeReaderStore } from "@/stores/barcode";
import { useCartStore } from "@/stores/cart";

const barcodeReader = useBarcodeReaderStore();
const cartStore = useCartStore();

const { canReadBarcode } = storeToRefs(barcodeReader);

const { addProductToCart } = cartStore;

const state = reactive({
  barcode: "",
});

const clearInputAndFocus = () => {
  if (!barcodeReader.canReadBarcode) return;
  let barcodeInput = document.getElementById("barcode-read-input");
  if (barcodeInput) {
    barcodeInput.value = "";
    barcodeInput.focus();
  }
};

const readBarcode = async (e) => {
  if (!barcodeReader.canReadBarcode) return;
  if (e.key === "Enter") {
    console.log("BARCODE: " + state.barcode);
    const productFound = await addProductToCart(state.barcode);
    barcodeReader.setProductFound(productFound);
    barcodeReader.setBarcode(state.barcode);
    state.barcode = "";
  } else {
    state.barcode += e.key;
  }
};

watch(
  canReadBarcode,
  async (newCanReadBarcodeValue, oldCanReadBarcodeValue) => {
    if (newCanReadBarcodeValue === true) {
      clearInputAndFocus();
    }
  }
);

onMounted(() => {
  if (process.env.NODE_ENV === "production") {
    clearInputAndFocus();
  }
});
</script>

<template>
  <div @keyup="readBarcode">
    <!-- hidden input for barcode reading -->
    <input
      readonly
      id="barcode-read-input"
      ref="barcode-read-input"
      @blur="clearInputAndFocus"
    />
  </div>
</template>

<style scoped>
#barcode-read-input {
  position: absolute;
  top: -500px;
  left: -500px;
}
</style>
