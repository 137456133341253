<script setup>
import { reactive, onMounted } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useWarningStore } from "@/stores/warning";

const route = useRoute();
const router = useRouter();

const warningStore = useWarningStore();

const state = reactive({
  barcode: route.params.data,
});

const returnToBarcodeRead = () => {
  router.replace("/barcode-reading");
};

onMounted(() => {
  warningStore.resetCounter();
  warningStore.startCounter();
});
</script>

<template>
  <div class="error-modal">
    <div class="error-modal-content">
      <svg
        class="itau-logo"
        width="300"
        height="300"
        viewBox="0 0 300 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M54.8638 0.000840131L245.47 0.167599C275.32 0.167599 300 24.848 300 54.8646V245.471C300 275.487 275.32 300.001 245.303 300.001L54.5303 299.834C24.5136 299.667 0 275.154 0 245.137L0.166759 54.5311C0.166759 24.5145 24.6804 -0.165919 54.8638 0.000840131Z"
          fill="#003399"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M257.643 136.743H235.964L226.959 153.752H242.135L257.643 136.743ZM272.151 164.425H250.973V210.117C250.973 224.125 241.634 228.127 234.63 228.127C227.96 228.127 220.789 225.625 220.789 213.452V164.425H199.611L199.444 216.454C199.444 236.631 210.951 245.636 226.959 245.636C238.132 245.803 246.637 240.967 251.473 232.463H251.64V243.635H271.984V164.591L272.151 164.425Z"
          fill="#FFF212"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M183.102 185.437C183.102 165.759 162.424 162.424 151.084 162.424C134.575 162.424 117.399 165.926 114.897 190.106H135.409C136.076 185.437 137.91 179.1 148.583 179.1C154.086 179.1 162.59 179.6 162.59 188.272C162.59 192.774 158.421 194.108 155.086 194.608L136.076 197.443C122.568 199.278 112.396 207.282 112.396 222.791C112.396 239.133 124.903 245.803 136.909 245.803C151.417 245.803 158.255 239.133 163.091 234.297C163.591 238.633 163.924 239.8 165.092 243.636L183.435 243.469C183.435 234.797 183.268 228.294 183.268 228.294V185.437H183.102Z"
          fill="#FFF212"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92.7182 136.743H71.5397V164.258H60.5336V179.1H71.373V227.126C71.373 237.299 74.5414 244.302 93.0517 244.302H95.8866C99.2218 244.302 102.557 244.136 105.892 243.969V228.127C104.391 228.294 102.891 228.46 101.556 228.46C92.8849 228.46 92.7182 226.626 92.7182 221.957L92.8849 179.1H106.059V164.258H92.8849V136.743H92.7182Z"
          fill="#FFF212"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50.8616 136.743H28.0156L27.8488 243.469H50.6948L50.8616 136.743Z"
          fill="#FFF212"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M162.424 215.286V205.114C160.256 206.781 156.754 208.115 147.915 209.45C139.911 210.784 133.407 212.785 133.407 221.456C133.407 228.627 139.244 230.961 143.246 230.961C152.585 230.961 162.257 224.958 162.257 215.453L162.424 215.286Z"
          fill="#3E4095"
        />
      </svg>

      <div class="error-modal-rectangle">
        <p class="text-error-title">
          Código de barras não encontrado ({{ state.barcode }})
        </p>

        <div class="div-button">
          <button class="btn-return" @click="returnToBarcodeRead">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error-modal {
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  background-color: #ec7000;
}
.error-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.itau-logo {
  margin-top: 9.5rem;
  margin-bottom: 7.75rem;
}
.error-modal-rectangle {
  display: flex;
  flex-direction: column;
  width: 47.5rem;
  height: 46.375rem;
  border-radius: 1.25rem;
  background: #fff;
}
.text-error-title {
  text-align: center;
  color: #656262;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 8.87rem;
  margin-bottom: 7.1rem;
}
.div-button {
  display: flex;
  justify-content: center;
}
.btn-return {
  width: 37.5rem;
  height: 7.5rem;
  border-radius: 0.625rem;
  background: #ec7000;
  color: #fff;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: unset;
}
</style>
