<script setup>
import { reactive } from "vue";

import { useRouter } from "vue-router";

const router = useRouter();

const state = reactive({
  itauLogoImg: "itau-logo-big.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/common", false, /\.svg$/);
  return images("./" + img);
};

const returnToCheckout = () => {
  router.replace("/barcode-reading");
};
</script>

<template>
  <div class="error-modal-modal">
    <div class="error-modal-modal-content">
      <img
        :src="getImgUrl(state.itauLogoImg)"
        alt="itau-logo"
        class="itau-logo"
      />
      <div class="error-modal-rectangle">
        <p class="text-error-title">
          Não existe Configuração
        </p>

        <div class="div-button">
          <button class="btn-return" @click="returnToCheckout">Entendi</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error-modal-modal {
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  background-color: #ec7000;
}
.error-modal-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.itau-logo {
  margin-top: 9.5rem;
  margin-bottom: 7.75rem;
}
.error-modal-rectangle {
  display: flex;
  flex-direction: column;
  width: 47.5rem;
  height: 46.375rem;
  border-radius: 1.25rem;
  background: #fff;
}
.text-error-title {
  text-align: center;
  color: #656262;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 8.87rem;
  margin-bottom: 7.1rem;
}
.div-button {
  display: flex;
  justify-content: center;
}
.btn-return {
  width: 37.5rem;
  height: 7.5rem;
  border-radius: 0.625rem;
  background: #ec7000;
  color: #fff;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: unset;
}
</style>
