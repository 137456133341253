<script setup>
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";

import { Device } from "@capacitor/device";

import { useLoginStore } from "@/stores/login";
import { useCommonStore } from "@/stores/common";
import { useWarningStore } from "@/stores/warning";
import { useTotemStore } from "@/stores/totem";

const loginStore = useLoginStore();
const commonStore = useCommonStore();
const warningStore = useWarningStore();

const router = useRouter();

const { login } = loginStore;

const state = reactive({
  username: "totem@onii.app", // TODO: remover ou deixar hardcoded?
  password: "#Totem123", // TODO: remover ou deixar hardcoded?

  deviceId: "",
  showPassword: false,
  invalidLogin: false,
  isLoginSpinnerActive: false,
  iconEyePath: "icon-password-toggle-eye.svg",
  iconEyeSlashIconPath: "icon-password-toggle-eye-slash.svg",
  iconLoginErrorInfoPath: "icon-login-error-info.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../assets/img/login", false, /\.svg$/);
  return images("./" + img);
};

const togglePasswordVisibility = () => {
  const password = document.querySelector("#password");
  const type =
    password.getAttribute("type") === "password" ? "text" : "password";
  password.setAttribute("type", type);
  state.showPassword = !state.showPassword;
};

const clearInput = () => {
  const username = document.getElementById("username");
  const password = document.getElementById("password");
  username.value = "";
  password.value = "";
};

const loginAccount = async () => {
  const username = document.getElementById("username").value;
  const password = document.getElementById("password").value;

  clearInput();
  state.isLoginSpinnerActive = true;

  await login({
    id: username,
    password: password,
    deviceId: state.deviceId,
  })
    .then((resp) => {
      if (resp.data.result === "ok") {
        state.invalidLogin = false;
        router.replace("welcome");
      } else {
        state.invalidLogin = true;
      }
    })
    .catch((err) => {
      console.log("ERRO LOGIN CATCH: ", JSON.stringify(err, null, "\t"));
      state.invalidLogin = true;
    })
    .finally(() => {
      state.isLoginSpinnerActive = false;
    });
};

onMounted(async () => {
  warningStore.clearCounter();

  // https://capacitorjs.com/docs/apis/device
  if (process.env.NODE_ENV === "development") {
    commonStore.setDeviceId("3b83b0d8a9fe13d3"); // SUNMI K2 MINI id
  } else {
    const deviceId = await Device.getId();
    commonStore.setDeviceId(deviceId.identifier.trim());
  }

  state.deviceId = commonStore.getDeviceId;
  console.log("DEVICE ID: " + state.deviceId);

  clearInput();
  state.invalidLogin = false;
});
</script>

<template>
  <div class="login-page">
    <div class="content-rectangle">
      <p class="text-label user-label">Usuário</p>

      <input
        type="text"
        id="username"
        v-model="state.username"
        required
        placeholder="Digite o usuário"
      />

      <p class="text-label password-label">Senha</p>
      <div class="password-container">
        <input
          type="password"
          id="password"
          v-model="state.password"
          required
          placeholder="Digite a senha"
          @change="state.invalidLogin = false"
        />
        <span
          @click="togglePasswordVisibility"
          id="togglePassword"
          class="password-toggler-icon"
        >
          <img
            :src="
              getImgUrl(
                state.showPassword
                  ? state.iconEyeSlashIconPath
                  : state.iconEyePath
              )
            "
          />
        </span>
      </div>

      <button
        @click="loginAccount"
        v-if="state.isLoginSpinnerActive === false"
        :disabled="state.username === ''"
        class="btn-sign-in"
      >
        Entrar
      </button>
      <div v-if="state.isLoginSpinnerActive" class="btn-loading">
        <o-loading
          v-model:active="state.isLoginSpinnerActive"
          :fullPage="false"
          :cancelable="false"
          ><o-icon
            pack="fas"
            icon="circle-notch"
            size="medium"
            variant="info"
            spin
          ></o-icon
        ></o-loading>
      </div>

      <div
        v-if="state.invalidLogin"
        @click="router.replace('login-error')"
        class="invalid-login-rectangle"
      >
        Usuário ou senha inválidos
        <img
          :src="getImgUrl(state.iconLoginErrorInfoPath)"
          class="icon-login-error-info"
        />
      </div>
    </div>

    <div class="div-app-info">
      <span class="text-app-info">Versão 4.60.5</span>
      <span class="text-app-info">{{ state.deviceId }}</span>
    </div>
  </div>
</template>

<style scoped>
input {
  width: 48.625rem;
  display: block;
  margin: 0 auto;
  padding-bottom: 0.44rem;
  border-color: #949494;
  border-width: 0 0 1px;
  outline: 0;
  color: black;
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
input::placeholder {
  color: #949494;
}
.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ec7000;
}
.content-rectangle {
  width: 51.5rem;
  height: 31.25rem;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #fff;
}
.text-label {
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 2rem;
}
.user-label {
  margin-top: 3.25rem;
  margin-bottom: 1.69rem;
}
.password-label {
  margin-top: 1.75rem;
  margin-bottom: 1.69rem;
}
.password-toggler-icon {
  position: absolute;
  top: -0.1rem;
  right: 2rem;
}
.password-toggler-icon > img {
  width: 1.1875rem;
  height: 1.5rem;
}
.btn-sign-in {
  display: block;
  margin: 2.5rem auto 0 auto;
  padding: 1rem 0;
  width: 48.625rem;
  height: 3.8125rem;
  border-style: unset;
  border-radius: 0.625rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.btn-sign-in:disabled {
  background: #d7d7d7;
  color: #000;
}
.btn-sign-in:enabled {
  background: #ec7000;
  color: #fff;
}
.btn-loading {
  display: block;
  margin: 2.5rem auto 0 auto;
  padding: 1rem 0;
  width: 48.625rem;
  height: 3.8125rem;
  background: #ec7000;
  border-radius: 0.625rem;
}
.btn-exit {
  display: block;
  margin: 1.2rem auto 0 auto;
  border: unset;
  background: none;
  color: #000;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.invalid-login-rectangle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.3rem auto 0 auto;
  width: 48.625rem;
  height: 3.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #e76f70;
  color: #e76f70;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.icon-login-error-info {
  position: absolute;
  right: 1rem;
  width: 1.8125rem;
  height: 1.8125rem;
}
.div-app-info {
  display: flex;
  justify-content: space-between;
  margin-top: 1.7rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
.text-app-info {
  color: #fff;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
