<template>
  <div class="div-text-welcome">
    <p class="text-welcome">
      Olá :)
      <br />
      Quer conhecer as ofertas pensadas para você?
    </p>
  </div>
</template>

<style scoped>
.div-text-welcome {
  display: flex;
  justify-content: center;
}
.text-welcome {
  color: #fff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3rem;
}
</style>
