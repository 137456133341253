<script setup>
import { reactive, defineEmits, onMounted } from "vue";

import { useCartStore } from "@/stores/cart";

const cartStore = useCartStore();

const emit = defineEmits(["update-payment-installments"]);

const state = reactive({
  selectedNumberOfInstallments: 1,
  numberOfInstallments: 0,
  installmentsArray: [],
});

const selectNumberOfInstallments = (numberOfInstallments) => {
  state.selectedNumberOfInstallments = numberOfInstallments;
  emit("update-payment-installments", state.selectedNumberOfInstallments);
};

const calculateInstallments = () => {
  const totalValue = cartStore.getTotalValue / 100;

  const installmentRules = [
    {
      value1: 50,
      numberOfInstallments: 3,
      operator1: "<=",
    },
    {
      value1: 50,
      value2: 100,
      numberOfInstallments: 6,
      operator1: ">",
      operator2: "<",
    },
    {
      value1: 100,
      numberOfInstallments: 12,
      operator1: ">=",
    },
  ];

  const matchingRule = installmentRules.find((rule) => {
    const validate = (totalValue, operator, operand) => {
      if (operator === "=") {
        return Number(totalValue) === Number(operand);
      } else if (operator === "<") {
        return Number(totalValue) < Number(operand);
      } else if (operator === ">") {
        return Number(totalValue) > Number(operand);
      } else if (operator === "<=") {
        return Number(totalValue) <= Number(operand);
      } else if (operator === ">=") {
        return Number(totalValue) >= Number(operand);
      }
      return false;
    };

    return (
      validate(totalValue, rule.operator1, rule.value1) &&
      (!rule.operator2 ||
        rule.operator2 === "" ||
        validate(totalValue, rule.operator2, rule.value2))
    );
  });

  if (matchingRule) {
    state.numberOfInstallments = matchingRule.numberOfInstallments;

    let installments = [];

    for (let i = 1; i <= state.numberOfInstallments; i++) {
      installments.push({
        installment: i,
        value: totalValue / i,
      });
    }

    console.log("Installments: ", installments);

    state.installmentsArray = installments;
  } else {
    state.numberOfInstallments = 1;
  }
};

onMounted(() => {
  calculateInstallments();
});
</script>

<template>
  <p class="installments-title">em quantas vezes?</p>

  <div class="installments-container">
    <div
      v-for="item in state.installmentsArray"
      :key="item.installment"
      class="installments-box"
      :class="
        state.selectedNumberOfInstallments === item.installment
          ? 'selected'
          : ''
      "
      @click="selectNumberOfInstallments(item.installment)"
    >
      <p>
        {{ item.installment }}x de R$
        {{ Number(item.value).toFixed(2).toString().replace(".", ",") }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.installments-title {
  color: #ec7000;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 3.75rem 0 1.75rem 3rem;
}
.installments-container {
  display: flex;
  margin-left: 3rem;
  overflow-x: auto;
}
.installments-container::-webkit-scrollbar {
  display: none;
}
.installments-box {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  border: 2px solid #d6d6d6;
  color: #000;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 1.2rem;
  padding: 1.25rem;
}
.selected {
  border: 2px solid #ec7000 !important;
}
</style>
