import { defineStore } from "pinia";
import $http from "../axios";

import { useUserStore } from "@/stores/user";
import { useCartStore } from "@/stores/cart";
import { useTotemStore } from "@/stores/totem";

export const useLoginStore = defineStore("login", {
  state: () => ({
    isAuthenticated: false,
    sessionToken: null,
    isTotemAccount: null,
  }),

  persist: true,

  getters: {
    getIsAuthenticated(state) {
      return state.isAuthenticated;
    },
    getSessionToken(state) {
      return state.sessionToken;
    },
  },

  actions: {
    login(credentials) {
      return new Promise((resolve, reject) => {
        $http
          .post("login", credentials)
          .then((res) => {
            if (res.data.result === "ok") {
              this.isAuthenticated = true;
              this.isTotemAccount = true;
              this.sessionToken = res.data.payload.sessionToken;
              console.log(
                "LOGIN DATA: ",
                JSON.stringify(res.data.payload, null, "\t")
              );

              useTotemStore().setTotemStoreId(res.data.payload.totemStoreId);
              // console.log("TOTEM STORE ID: " + useTotemStore().getTotemStoreId);
            } else {
              this.isAuthenticated = false;
              this.sessionToken = null;
              this.isTotemAccount = false;

              useTotemStore().setTotemStoreId(null);
              useTotemStore().totemManufacturer = "";
              useTotemStore().totemModel = "";
            }
            resolve(res);
          })
          .catch((e) => {
            this.isAuthenticated = false;
            this.sessionToken = null;
            this.isTotemAccount = false;

            useTotemStore().setTotemStoreId(null);
            useTotemStore().totemManufacturer = "";
            useTotemStore().totemModel = "";
            reject(e);
          });
      });
    },

    logout() {
      this.isAuthenticated = false;
      this.sessionToken = null;

      const cartStore = useCartStore();
      const userStore = useUserStore();

      const { emptyCart } = cartStore;
      const { clearPersonalId } = userStore;

      clearPersonalId();
      emptyCart();
    },
  },
});
