import { registerPlugin } from '@capacitor/core';

import type { kioskPlugin } from './definitions';

const kiosk = registerPlugin<kioskPlugin>('kiosk', {
  web: () => import('./web').then(m => new m.kioskWeb()),
  
});

export * from './definitions';
export { kiosk };
