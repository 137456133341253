<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";

import itauLogo from "@/assets/img/common/logo-itau-small.png";

import { useLoginStore } from "@/stores/login";

const router = useRouter();

const loginStore = useLoginStore();
const { logout } = loginStore;

const state = reactive({
  password: "",
});

const checkPassword = (char) => {
  state.password += char;
  console.log(state.password);

  // Vai para o painel de kiosk mode
  if (state.password === "101010") {
    state.password = "";
    router.replace("test-panel");
  }
  // Desloga e vai para página inicial de login
  else if (state.password === "010101") {
    state.password = "";
    logout();
    router.replace("/");
  }
};

// Se clicar no topo da página, reseta a password
const resetPassword = () => {
  state.password = "";
};
</script>

<template>
  <div class="header-div" @click="resetPassword">
    <div class="brand-div">
      <p class="itau-name" @click.stop="checkPassword(1)">Itaú Shop</p>
      <div @click.stop="checkPassword(0)">
        <img :src="itauLogo" alt="Itau Logo" class="logo-img" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.header-div {
  background: linear-gradient(180deg, #7a3900, transparent);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  z-index: 10;
  height: 330px;
}
.brand-div {
  width: 100%;
  margin: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itau-name {
  margin-left: 4rem;
  color: #fff;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-img {
  width: 6.6875rem;
  height: 6.6875rem;
  margin-right: 4rem;
}
</style>
