import { defineStore } from "pinia";

export const useCardStore = defineStore("card", {
  state: () => ({
    noAlcoholicsCardBrands: [],
    needsCVV: [],
  }),

  actions: {
    setNoAlcoholicsCardBrands(noAlcoholicsCardBrands) {
      this.noAlcoholicsCardBrands = noAlcoholicsCardBrands;
    },

    setNeedsCVV(needsCVV) {
      this.needsCVV = needsCVV;
    }
  },
});
