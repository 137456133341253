import { defineStore } from "pinia";
import $http from "../axios";

export const useCheckoutStore = defineStore("checkout", {
  state: () => ({
    payments: {
      provider: "",
      terminal: {
        acceptedBrands: {},
      },
      sitef: {},
      gsurf: {},
      ame: {},
      currency: "BRL",
    },
  }),

  getters: {
    getPayments(state) {
      return state.payments;
    },
  },

  actions: {
    createTefCheckoutAction(data) {
      // console.log("CREATE TEF CHECKOUT: ", JSON.stringify(data, null, "\t"));
      return new Promise((resolve, reject) => {
        $http
          .post("create-tef-checkout", data)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    updateTefCheckoutAction(data) {
      return new Promise((resolve, reject) => {
        $http
          .post("update-tef-checkout", data)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    checkTransaction() {
      return new Promise((resolve, reject) => {
        $http
          .get("check-tef-transaction")
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
});
