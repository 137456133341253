<template>
  <h1 class="modal-title">
    Você ainda está por aí?
  </h1>

  <p class="modal-message">
    Toque na tela para continuar a experiência
  </p>
</template>


<style scoped>
.modal-title {
  font-size: 2.75rem;
  font-weight: 700;
  text-align: center;
  color: #F0502E;
  /* width: 100%; */
  border-bottom: 1px solid #dbdbdb;
  background-color: #f0f0f0;
  padding: 14px;
  position: relative;
  text-align: center;
}
.modal-message {
  padding: 1rem;
  font-size: 2.25rem;
  text-align: center;
}
</style>
