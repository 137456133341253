<script setup>
import { reactive, onMounted, computed } from "vue";

import { useRouter } from "vue-router";

import { useWarningStore } from "@/stores/warning";
import { useErrorStore } from "@/stores/error";

const router = useRouter();

const warningStore = useWarningStore();

const state = reactive({
  itauLogoImg: "itau-logo-big.svg",
  warningSignImg: "warning-sign.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/common", false, /\.svg$/);
  return images("./" + img);
};

const returnToCheckout = () => {
  useErrorStore().setSalesErrorOccurence(true);
  useErrorStore().clearSalesErrorInfo();
  router.replace("/barcode-reading");
};

const errorCode = computed(() => {
  if (process.env.NODE_ENV === "development") {
    return "-1";
  } else {
    return useErrorStore().getSalesErrorCode.toString();
  }
});

const errorMessage = computed(() => {
  if (process.env.NODE_ENV === "development") {
    return "Falha na operação MODO_DEV";
  } else {
    return useErrorStore().getSalesErrorMessage;
  }
});

onMounted(() => {
  warningStore.resetCounter();
  warningStore.startCounter();
});
</script>

<template>
  <div class="error-modal">
    <div class="error-modal-content">
      <img
        :src="getImgUrl(state.itauLogoImg)"
        alt="itau-logo"
        class="itau-logo"
      />
      <div class="error-rectangle">
        <img
          :src="getImgUrl(state.warningSignImg)"
          alt="warning"
          class="warning-sign"
        />
        <p class="text-error-title">Ops! Algo Falhou ao confirmar a venda</p>
        <p class="text-error-description">
          Tente repetir a operação. Caso o problema seja recorrente, abra um
          chamado incluindo uma fotografia da tela.
        </p>
        <p class="text-type-error-title">Tipo de erro:</p>
        <p class="text-type-error-description">
          {{ errorMessage }}
        </p>
        <p class="text-type-error-title">Código do erro:</p>
        <p class="text-type-error-description">{{ errorCode }}</p>

        <div class="div-button">
          <button class="btn-return" @click="returnToCheckout">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error-modal {
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  background-color: #ec7000;
}
.error-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.itau-logo {
  margin-top: 9.5rem;
  margin-bottom: 7.75rem;
}
.error-rectangle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 47.5rem;
  height: 46.375rem;
  border-radius: 1.25rem;
  background: #fff;
}
.warning-sign {
  width: 4.375rem;
  height: 4.375rem;
  margin-top: 2.56rem;
  margin-bottom: 1.25rem;
}
.text-error-title {
  text-align: center;
  color: #656262;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
}
.text-error-description {
  text-align: center;
  color: #949494;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1.5rem;
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}
.text-type-error-title {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.text-type-error-description {
  color: #949494;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1.8rem;
}
.div-button {
  display: flex;
  justify-content: center;
}
.btn-return {
  width: 37.5rem;
  height: 7.5rem;
  border-radius: 0.625rem;
  background: #ec7000;
  color: #fff;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: unset;
}
</style>
