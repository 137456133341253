//TODO: verificar configuração do axios

import axios from "axios";
import { useErrorStore } from '@/stores/error'
import { useLoadingStore } from '@/stores/loading'
import { useLoginStore } from "@/stores/login"

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "appVersion": process.env.VUE_APP_APP_VERSION,
    "locale": "pt_BR",
  }
});

http.interceptors.request.use(
  config => {
    // add authentication token
    if (useLoginStore().isAuthenticated) {
      config.headers['Authorization'] = 'Bearer ' + useLoginStore().sessionToken;
    }

    // add locale
    // if (store.getters.locale) {
    //   config.headers['locale'] = store.getters.locale;
    // }

    // add device info
    // config.headers.common['device'] = JSON.stringify(store.getters.device);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  res => {
    const loadingStore = useLoadingStore()
    // loadingStore.isLoading = false

    if (res.data.result === "error") {
      const errorStore = useErrorStore()
      errorStore.alertError(res.data)
    }

    return res;
  },
  error => {
    console.log("AXIOS ERROR: ", JSON.stringify(error, null, '\t'));

    const loadingStore = useLoadingStore()
    // loadingStore.isLoading = false
    
    // skips not found for testing (because API is only accessible from inside the network)
    // if (error.response.status !== 404) {s
    //   const errorStore = useErrorStore()
    //   errorStore.alertError({ message: "UNEXPECTED_ERROR" })
    // }

    return Promise.reject(error);
  }
)

export default http;
