<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const testKioskMode = async () => {
  try {
    await KioskMode.isEnabled();
  } catch (e) {
    console.log(e);
  }
};

const testLauncher = async () => {
  try {
    await KioskMode.isLauncher();
  } catch (e) {
    console.log(e);
  }
};

const enableKiosk = () => {
  try {
    KioskMode.enable();
  } catch (e) {
    console.log(e);
  }
};

const exitKiosk = () => {
  try {
    KioskMode.disable();
  } catch (e) {
    console.log(e);
  }
};

const switchLauncher = () => {
  try {
    KioskMode.switchLauncher();
  } catch (e) {
    console.log(e);
  }
};

const exitScreen = () => {
  router.replace("/welcome");
};
</script>

<template>
  <div class="test-panel-page">
    <p class="text-test-panel-title">PAINEL DE CONFIGURAÇÃO</p>
    <br />
    <button class="button btn" @click="testKioskMode">Teste Quiosque</button>
    &nbsp;&nbsp;

    <button class="button btn" @click="testLauncher">Teste Launcher</button>
    &nbsp;&nbsp;

    <button class="button btn" @click="enableKiosk">Habilitar Quiosque</button>
    &nbsp;&nbsp;

    <button class="button btn" @click="exitKiosk">Desabilitar Quiosque</button>
    &nbsp;&nbsp;

    <button class="button btn" @click="switchLauncher">Trocar Launcher</button>
    &nbsp;&nbsp;

    <button class="button btn btn-exit" @click="exitScreen">SAIR</button>
  </div>
</template>

<style scoped>
.test-panel-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #EC7000;
}
.text-test-panel-title {
  font-size: 4rem;
  color: white;
  font-weight: 400;
  margin-bottom: 2.5rem;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40rem;
  height: 8.9375rem;
  border-radius: 1.25rem;
  background: #003399;
  border: unset;
  color: #fff;
  text-align: center;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn-exit {
  margin-top: 2rem;
  background-color: darkred;
}
</style>
