import { createRouter, createWebHistory } from "vue-router";

import Login from "./views/Login.vue";
import TestPanel from "./views/TestPanel.vue";
import Welcome from "./views/Welcome.vue";
import BarcodeReading from "./views/BarcodeReading.vue";
import TransactionFinished from "./views/TransactionFinished.vue";

import LoginError from "./views/error-screens/LoginError.vue";
import BarcodeError from "./views/error-screens/BarcodeError.vue";
import NfcError from "./views/error-screens/NfcError.vue";
import SalesError from "./views/error-screens/SalesError.vue";
import ConfigError from "./views/error-screens/ConfigError.vue";
import OperationError from "./views/error-screens/OperationError.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
    },
    {
      path: "/test-panel",
      name: "test-panel",
      component: TestPanel,
    },
    {
      path: "/welcome",
      name: "welcome",
      component: Welcome,
    },
    {
      path: "/barcode-reading",
      name: "barcode-reading",
      component: BarcodeReading,
    },
    {
      path: "/transaction-finished",
      name: "transaction-finished",
      component: TransactionFinished,
    },
    {
      path: "/login-error",
      name: "login-error",
      component: LoginError,
    },
    {
      path: "/nfc-error",
      name: "nfc-error",
      component: NfcError,
    },
    {
      path: "/sales-error",
      name: "sales-error",
      component: SalesError,
    },
    {
      path: "/barcode-error/:data",
      name: "barcode-error",
      component: BarcodeError,
    },
    {
      path: "/config-error",
      name: "config-error",
      component: ConfigError,
    },
    {
      path: "/operation-error",
      name: "operation-error",
      component: OperationError,
    },
  ],
});

export default router;
