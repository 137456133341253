import { defineStore } from "pinia";

import { useLoadingStore } from "@/stores/loading";
import { useProductStore } from "@/stores/product";
import { useTotemStore } from "@/stores/totem";
import { useWarningStore } from "@/stores/warning";
import { useCommonStore } from "@/stores/common";

export const useCartStore = defineStore("cart", {
  state: () => ({
    cart: {
      items: [],
      catalogId: null,
      totalValue: 0,
    },
  }),

  getters: {
    getCatalogId(state) {
      return state.cart.catalogId;
    },

    getTotalItemsQuantity(state) {
      let total = 0;
      for (let i = 0; i < state.cart.items.length; i++) {
        total += Number(state.cart.items[i].quantity);
      }
      return total;
    },

    getTotalValue(state) {
      let total = 0;
      for (let i = 0; i < state.cart.items.length; i++) {
        total +=
          Number(state.cart.items[i].price) *
          Number(state.cart.items[i].quantity);
      }
      return Number(total.toFixed(0));
    },
  },

  actions: {
    async addProductToCart(barcode) {
      const loadingStore = useLoadingStore();
      loadingStore.setLoading(true, "Carregando Catálogo");

      const warningStore = useWarningStore();
      warningStore.resetCounter();

      const totem = useTotemStore();
      const productStore = useProductStore();
      const commonStore = useCommonStore();

      let productToAdd = null;

      // Load store catalog
      if (this.cart.catalogId === null) {
        await useProductStore().getProducts(totem.getTotemStoreId);
      }

      // Load totem info
      if (
        totem.getTotemPayments === null &&
        totem.getTotemNationalCompanyId === ""
      ) {
        await totem.getTotemInfo({
          deviceId: commonStore.getDeviceId,
        });
      }

      // Search for barcode in catalog
      let barcodesToSearch = [barcode];
      let catalogProductIndex = useProductStore().products.findIndex(
        (p) => p.b === barcode
      );

      // if product not found, try to search with 1 left 0
      if (catalogProductIndex === -1 && productStore.tryFillOneLeftZero) {
        barcodesToSearch.push(`0${barcode}`);
        catalogProductIndex = productStore.products.findIndex(
          (p) => p.b === `0${barcode}`
        );
      }

      // if product not found, try to search with 2 left 0s
      if (catalogProductIndex === -1 && productStore.tryFillTwoLeftZeros) {
        barcodesToSearch.push(`00${barcode}`);
        catalogProductIndex = productStore.products.findIndex(
          (p) => p.b === `00${barcode}`
        );
      }

      if (catalogProductIndex === -1) {
        await useProductStore()
          .searchProductOtherStores({
            storeId: totem.totemStoreId,
            barcodesToSearch,
          })
          .then((res) => {
            if (res.data.result === "ok" && res.data.payload.product) {
              // Add Product to Cart
              const product = res.data.payload.product;
              productToAdd = {
                productId: product._id,
                quantity: 1,
                price: product.p,
                barcode: product.b,
                name: product.n,
                imageURL: product.i,
                alcoholic: product.a || false,
                category: product.c,
                subcategory: product.s,
                scaleProduct: false,
              };
            } else {
              console.log("Type Barcode"); // TODO: vai ter opção de digitar manualmente?
              // this.typeBarcode();
            }
          })
          .catch((err) => {
            console.log("Erro em addProductToCart", JSON.stringify(err, null, "\t"));
            // this.typeBarcode(); // TODO: ?
          });
      } else {
        // Add item from catalog to cart
        const product = useProductStore().products[catalogProductIndex];
        productToAdd = {
          productId: product._id,
          quantity: 1,
          price: product.p,
          barcode: product.b,
          name: product.n,
          imageURL: product.i,
          alcoholic: product.a || false,
          category: product.c,
          subcategory: product.s,
          scaleProduct: false,
        };
      }

      const existingProduct = this.cart.items.findIndex((item) =>
        barcodesToSearch.includes(item.barcode)
      );

      if (existingProduct >= 0) {
        this.incrementProduct(existingProduct);
      } else if (productToAdd) {
        this.cart.items.push(productToAdd);
      } else {
        this.$router.replace({
          name: "barcode-error",
          params: { data: barcode },
        });
      }

      loadingStore.isLoading = false;

      return productToAdd !== undefined && productToAdd !== null;
    },

    incrementProduct(productIndex) {
      if (productIndex >= 0 && productIndex < this.cart.items.length) {
        this.cart.items[productIndex].quantity++;
      }
    },

    decrementProduct(productIndex) {
      if (productIndex >= 0 && productIndex < this.cart.items.length) {
        this.cart.items[productIndex].quantity--;
        if (this.cart.items[productIndex].quantity === 0) {
          this.cart.items.splice(productIndex, 1);
        }
      }
    },

    emptyCart() {
      this.cart.items = [];
    },

    emptyCatalog() {
      this.cart.catalogId = null;
    },

    setCatalogId(catalogId) {
      this.cart.catalogId = catalogId;
    },
  },
});
