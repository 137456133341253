import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import Oruga from "@oruga-ui/oruga-next";
import Socketio from "@/plugins/socketio.js";
import moment from "moment";

import "./assets/css/main.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
  faCircleNotch,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faChevronLeft, faChevronRight, faTimes, faCircleNotch, faSyncAlt);

const app = createApp(App);

app.component("vue-fontawesome", FontAwesomeIcon);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});
app.use(pinia);

app.use(router);

app.use(Oruga, {
  iconComponent: "vue-fontawesome",
  iconPack: "fas",
});

app.use(Socketio, {
  connection: process.env.VUE_APP_SOCKET_SERVER_ENDPOINT,
  options: {
    auth: {
      token: process.env.VUE_APP_SOCKET_SERVER_TOKEN,
    },
  },
});

moment.locale("pt-BR");
app.config.globalProperties.$moment = moment;

app.use(i18n);

app.mount("#app");
