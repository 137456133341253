<script setup>
import { reactive } from "vue";

const state = reactive({
  imgBarcode: "barcode-image.svg",
  imgLookDownArrow: "look-down-arrow.svg",
});

const getImgUrl = (img) => {
  let images = require.context(
    "../../assets/img/barcode-reading",
    false,
    /\.svg$/
  );
  return images("./" + img);
};
</script>

<template>
  <div class="div-barcode-info">
    <p class="barcode-instructions">
      aproxime a comanda ou o produto<br />
      no leitor de código de barras.
    </p>

    <img :src="getImgUrl(state.imgBarcode)" class="img-barcode" />

    <img :src="getImgUrl(state.imgLookDownArrow)" class="img-look-down-arrow" />
  </div>
</template>

<style scoped>
.div-barcode-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.barcode-instructions {
  color: #fff;
  text-align: center;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.62rem;
}
.img-barcode {
  width: 11.3125rem;
  height: 9.125rem;
  margin-bottom: 4.94rem;
}
.img-look-down-arrow {
  width: 5.6875rem;
  height: 6.8125rem;
}
</style>
