import { defineStore } from "pinia";

export const useWarningStore = defineStore("warning", {
  state: () => ({
    interval: null,
    INITIAL_COUNTER: 60,
    WARNING_TIME: 5,
    counter: 20,
    width: 750,
    isWarningModalOpen: false,
  }),

  persist: true,

  actions: {
    resetCounter() {
      this.counter = this.INITIAL_COUNTER;
      this.isWarningModalOpen = false;
    },

    startCounter() {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.counter--;
          console.log(this.counter);
          if (this.counter === this.WARNING_TIME) {
            console.log("warning");
            this.isWarningModalOpen = true;
          } else if (this.counter === 0) {
            console.log("time's up");
            clearInterval(this.interval);
            this.isWarningModalOpen = false;
            this.$router.replace("/welcome");
          }
        }, 1000);
      }
    },

    clearCounter() {
      clearInterval(this.interval);
      this.interval = null;
    },
  },
});
