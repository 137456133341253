<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";

import itauLogo from "@/assets/img/common/logo-itau-small.png";

const router = useRouter();

const state = reactive({
  goBackArrowIcon: "go-back-arrow.svg",
});

const getImgUrl = (img) => {
  let images = require.context(
    "../../assets/img/barcode-reading",
    false,
    /\.svg$/
  );
  return images("./" + img);
};
</script>

<template>
  <div class="top-nav">
    <div class="left-nav" @click="router.replace('welcome')">
      <img :src="getImgUrl(state.goBackArrowIcon)" class="img-go-back-arrow" />
      <p class="text-itau">Itaú Shop</p>
    </div>

    <div class="right-nav">
      <img :src="itauLogo" alt="Itau Logo" class="logo-img" />
    </div>
  </div>
</template>

<style scoped>
.top-nav {
  display: flex;
  height: 7rem;
  align-items: center;
}
.left-nav {
  display: flex;
  align-items: center;
  width: 50vw;
}
.right-nav {
  display: flex;
  width: 50vw;
  justify-content: flex-end;
}
.img-go-back-arrow {
  width: 1.125rem;
  height: 0.625rem;
  margin-left: 3.5rem;
  margin-right: 0.56rem;
}
.text-itau {
  color: #fff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.logo-img {
  width: 3.625rem;
  height: 3.625rem;
  margin-right: 4rem;
}
</style>
