<script setup>
import { defineProps } from "vue";

const props = defineProps(["title", "width", "height", "marginTop"]);
</script>

<template>
  <div
    class="div-button-continue"
    :style="props.marginTop ? `margin-top: ${props.marginTop}` : ''"
  >
    <button
      class="btn-continue"
      :style="
        props.width && props.height
          ? `width: ${props.width}; height: ${props.height}`
          : ''
      "
    >
      {{ props.title }}
    </button>
  </div>
</template>

<style scoped>
.div-button-continue {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-top: 4.2rem;
}
.btn-continue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52.125rem;
  height: 8.9375rem;
  border-radius: 1.25rem;
  background: #ec7000;
  border: unset;
  color: #fff;
  text-align: center;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
</style>
