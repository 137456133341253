import { defineStore } from "pinia";

export const useLoadingStore = defineStore("loading", {
  state: () => ({
    isFullPage: true,
    isLoading: false,
    canCancel: false,
    message: "",
  }),

  actions: {
    setLoading(isLoading, message = "") {
      this.setMessage(message);
      this.isLoading = isLoading;
    },

    setMessage(message) {
      if (message) {
        this.message = message;
      } else {
        this.message = "";
      }
    },
  },
});
