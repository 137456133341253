<script setup>
import { reactive, defineEmits } from "vue";

const emit = defineEmits(["payment-method-selection"]);

const state = reactive({
  paymentSelection: "",
  paymentCardIcon: "payment-card.svg",
  paymentCardSelectedIcon: "payment-card-orange.svg",
  pixIcon: "payment-pix.svg",
  pixSelectedIcon: "payment-pix-orange.svg",
});

const getImgUrl = (img) => {
  let images = require.context(
    "../../assets/img/checkout-modal",
    false,
    /\.svg$/
  );
  return images("./" + img);
};

const paymentSelection = (paymentOption) => {
  if (paymentOption === "debit") {
    state.paymentSelection = "debit";
    emit("payment-method-selection", state.paymentSelection);
  } else if (paymentOption === "credit") {
    state.paymentSelection = "credit";
    emit("payment-method-selection", state.paymentSelection);
  } else if (paymentOption === "pix") {
    state.paymentSelection = "pix";
    emit("payment-method-selection", state.paymentSelection);
  }
};
</script>

<template>
  <div class="card-container">
    <div
      @click="paymentSelection('debit')"
      class="card"
      :class="state.paymentSelection === 'debit' ? 'selected' : ''"
    >
      <img
        :src="
          state.paymentSelection === 'debit'
            ? getImgUrl(state.paymentCardSelectedIcon)
            : getImgUrl(state.paymentCardIcon)
        "
      />
      <p class="text-payment-method">Débito</p>
      <p class="text-payment-installments">à vista</p>
    </div>

    <div
      @click="paymentSelection('credit')"
      class="card"
      :class="state.paymentSelection === 'credit' ? 'selected' : ''"
    >
      <img
        :src="
          state.paymentSelection === 'credit'
            ? getImgUrl(state.paymentCardSelectedIcon)
            : getImgUrl(state.paymentCardIcon)
        "
      />
      <p class="text-payment-method">Crédito</p>
      <p class="text-payment-installments">até 12x</p>
    </div>

    <div
      @click="paymentSelection('pix')"
      class="card"
      :class="state.paymentSelection === 'pix' ? 'selected' : ''"
    >
      <div class="card-pix">
        <img
          :src="
            state.paymentSelection === 'pix'
              ? getImgUrl(state.pixSelectedIcon)
              : getImgUrl(state.pixIcon)
          "
        />
        <p class="text-payment-method">PIX</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-container {
  display: flex;
  margin-left: 2rem;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 14.25rem;
  height: 18rem;
  border-radius: 1.875rem;
  border: 2px solid #949494;
  margin-right: 1.2rem;
}
.card-pix {
  position: absolute;
  top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selected {
  border: 2px solid #ec7000 !important;
}
.text-payment-method {
  color: #000;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0.88rem;
}
.text-payment-installments {
  color: #000;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
