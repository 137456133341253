<script setup>
import { reactive } from "vue";

import { useLoadingStore } from "@/stores/loading";

const loadingStore = useLoadingStore();

const state = reactive({
  basketImg: "transaction-basket.svg",
});

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/common", false, /\.svg$/);
  return images("./" + img);
};
</script>

<template>
  <div class="loading-page">
    <p class="text-instructions">
      {{ loadingStore.message }}
    </p>

    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div class="basket">
      <img :src="getImgUrl(state.basketImg)" alt="basket" />
    </div>
  </div>
</template>

<style scoped>
.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ec7000;
  z-index: 20;
}
.text-instructions {
  color: #fff;
  text-align: center;
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 12.87rem;
}
.basket {
  display: flex;
  justify-content: center;
  margin-top: 6rem;
}

.lds-ring {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-top: 16.5rem;
  position: relative;
  z-index: 0;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25rem;
  height: 25rem;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
