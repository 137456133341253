<script setup>
import { reactive, defineProps, onMounted } from "vue";

import { useCartStore } from "@/stores/cart";

const cartStore = useCartStore();

const props = defineProps([
  "itemName",
  "itemBarcode",
  "itemPrice",
  "itemQuantity",
  "itemImageUrl",
]);

const state = reactive({
  itemImage: "",
});

onMounted(() => {
  if (process.env.NODE_ENV === "development") {
    state.itemImage =
      props.itemImageUrl && props.itemImageUrl != ""
        ? `https://s3.amazonaws.com/stage.assets.onii.com.br/products/${props.itemBarcode}/product-main-image/${props.itemImageUrl}.png`
        : "https://placehold.co/110x110";
  } else {
    state.itemImage =
      props.itemImageUrl && props.itemImageUrl != ""
        ? `https://s3.amazonaws.com/assets.onii.com.br/products/${props.itemBarcode}/product-main-image/${props.itemImageUrl}.png`
        : "https://placehold.co/110x110";
  }
});
</script>

<template>
  <div class="div-item">
    <div class="div-item-left-elements">
      <div class="div-item-image">
        <img class="item-image" :src="state.itemImage" alt="Item" />
      </div>
    </div>

    <div class="div-item-right-elements">
      <p class="item-description">
        {{ props.itemName }}
      </p>
      <div class="div-price-and-quantity">
        <p class="item-quantity">{{ props.itemQuantity }} und.</p>
        <p class="item-price">
          R$
          {{
            Number((props.itemPrice * props.itemQuantity) / 100)
              .toFixed(2)
              .toString()
              .replace(".", ",")
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.div-item {
  display: flex;
  width: 90%;
  height: 8.75rem;
  border-radius: 1.875rem;
  border: 1px solid #949494;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.8rem;
  padding-right: 2rem;
}
.div-item-left-elements {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}
.div-item-image {
  width: 6.875rem;
  height: 6.875rem;
  border-radius: 1rem;
  overflow: hidden;
}
.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.div-item-right-elements {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 4.5rem;
}
.item-description {
  display: flex;
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.div-price-and-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-quantity {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.item-price {
  color: #2e3191;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
