<script setup>
import { reactive, onMounted, onBeforeUnmount, defineEmits } from "vue";

import { useRouter } from "vue-router";

import { useCartStore } from "@/stores/cart";
import { useLoadingStore } from "@/stores/loading";
import { useWarningStore } from "@/stores/warning";

const router = useRouter();

const cartStore = useCartStore();
const loadingStore = useLoadingStore();
const warningStore = useWarningStore();

const emit = defineEmits(["close-pix-modal"]);

const state = reactive({
  pixCodeImage: "pix-code.svg",
  timerCountSeconds: 32,
  timer: null,
});

const goToPayment = async () => {
  warningStore.clearCounter();

  loadingStore.setLoading(true, "Servidor Conectado");
  const promise = () => new Promise((resolve) => setTimeout(resolve, 1000));
  await promise();
  loadingStore.setMessage("Aproxime, insira ou passe o cartão na leitora");
  await promise();
  loadingStore.setMessage("Método selecionado: PIX");
  await promise();
  loadingStore.setMessage("Aguarde, em processamento...");
  await promise();
  loadingStore.setMessage("Transação Aprovada");
  await promise();
  loadingStore.setMessage("Conectando ao Servidor");
  await promise();
  loadingStore.setMessage("Confirmando Venda");
  await promise();
  loadingStore.setMessage("Imprimindo o cupom da venda");
  await promise();

  loadingStore.isLoading = false;

  router.replace("/transaction-finished");
};

const getImgUrl = (img) => {
  let images = require.context("../../assets/img/pix-modal", false, /\.svg$/);
  return images("./" + img);
};

const closePixModal = () => {
  emit("close-pix-modal");
};

const decrementTimer = () => {
  if (state.timerCountSeconds > 0) {
    state.timerCountSeconds--;
  } else {
    clearInterval(state.timer);
    state.timer = null;
  }
};

onMounted(() => {
  state.timer = setInterval(decrementTimer, 1000);
});

onBeforeUnmount(() => {
  if (state.timer) {
    clearInterval(state.timer);
  }
});
</script>

<template>
  <div class="pix-modal">
    <div class="pix-modal-content">
      <p class="text-payment-processing">
        Aguarde, em processamento ... ({{ state.timerCountSeconds }})
      </p>

      <div class="pix-modal-rectangle">
        <img
          :src="getImgUrl(state.pixCodeImage)"
          alt="pix-code"
          @click="goToPayment"
        />
        <p class="text-pay-with-pix">Pagar com PIX</p>
        <p class="text-awaiting-payment">Aguardando pagamento</p>
        <p class="text-total-value">
          R$
          {{
            Number(cartStore.getTotalValue / 100)
              .toFixed(2)
              .toString()
              .replace(".", ",")
          }}
        </p>
      </div>

      <div class="div-button">
        <button class="btn-close-modal" @click="closePixModal">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pix-modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100vw;
  height: 100vh;
  background-color: #ec7000;
}
.pix-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pix-modal-rectangle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 47.5rem;
  height: 46.375rem;
  border-radius: 1.25rem;
  background: #fff;
}
.text-payment-processing {
  color: #fff;
  text-align: center;
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 12.87rem;
  margin-bottom: 10.8rem;
}
.text-pay-with-pix {
  color: #656262;
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 4.7rem;
  margin-bottom: 1.95rem;
}
.text-awaiting-payment {
  color: #656262;
  text-align: center;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.87rem;
}
.text-total-value {
  color: #ec7000;
  text-align: center;
  font-size: 3rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.div-button {
  display: flex;
  justify-content: center;
  margin-top: 6.81rem;
  margin-bottom: 2.2rem;
}
.btn-close-modal {
  width: 37.5rem;
  height: 7.5rem;
  border-radius: 0.625rem;
  background: #fff;
  color: #ee7e18;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: unset;
}
</style>
