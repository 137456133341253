<script setup>
import { onMounted, inject } from "vue";
import { RouterView, useRouter } from "vue-router";

import { kiosk } from "onii-kiosk-mode";
import { Device } from "@capacitor/device";
import { clisitef } from "onii-clisitef";

import moment from "moment";

import { useCartStore } from "@/stores/cart";
import { useCommonStore } from "@/stores/common";
import { useErrorStore } from "@/stores/error";
import { useLoadingStore } from "@/stores/loading";
import { useLocaleStore } from "@/stores/locale";
import { useLoginStore } from "@/stores/login";
import { useUserStore } from "@/stores/user";
import { useWarningStore } from "@/stores/warning";

import WarningModal from "./components/common/WarningModal.vue";
import Loading from "./components/common/Loading.vue";
import BarcodeReader from "./components/barcode-reading/BarcodeReader.vue";

const router = useRouter();

const cartStore = useCartStore();
const loadingStore = useLoadingStore();
const loginStore = useLoginStore();
const warningStore = useWarningStore();

const errorStore = useErrorStore();
const { alertError } = errorStore;

const userStore = useUserStore();
const { setSocketId } = userStore;

const localeStore = useLocaleStore();
const { setLocale } = localeStore;
setLocale(navigator.language.replace("-", "_"));

const commonStore = useCommonStore();
const { setDeviceInfo } = commonStore;

const socket = inject("socket");
socket.on("connect", () => {
  setSocketId(socket.id);
});

socket.on("PAYMENT_STATUS_CHANGE", (data) => {
  if (data.catalogId === cartStore.cart.catalogId) {
    if (data.status === "success") {
      // transactionConfirmed(data); // TODO: verificar
      console.log("Transação Confirmada");
    } else if (data.status === "cancelled") {
      alertError({ message: "Pagamento Cancelado" });
    } else if (data.status === "refused") {
      alertError({ message: "Pagamento Recusado" });
    }
  }
});

onMounted(async () => {
  warningStore.resetCounter();

  await kiosk.enable();

  const deviceInfo = await Device.getInfo();
  setDeviceInfo(deviceInfo);

  console.log("ENV: " + process.env.NODE_ENV);
  console.log("API ENDPOINT: " + process.env.VUE_APP_API_ENDPOINT);

  // Prevent blank screen
  if (loginStore.isAuthenticated) {
    router.replace("/welcome");
  } else {
    router.replace("/");
  }

  // FOR DEV PURPOSES: para testar rotas manualmente
  // router.replace("barcode-error/0123456789");
});
</script>

<template>
  <RouterView />

  <barcode-reader />

  <loading v-if="loadingStore.isLoading" />

  <o-modal
    v-model:active="warningStore.isWarningModalOpen"
    :onCancel="warningStore.resetCounter"
    :width="warningStore.width"
  >
    <warning-modal />
  </o-modal>
</template>

<!-- For Debugging Purposes: -->
<!-- <style type="text/css">
* {
  outline: 1px solid red;
}
</style> -->
