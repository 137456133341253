<script setup>
import { reactive, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

import TopNavigationBar from "@/components/barcode-reading/TopNavigationBar.vue";
import BarcodeInstructions from "@/components/barcode-reading/BarcodeInstructions.vue";
import CartModal from "@/components/cart-modal/CartModal.vue";
import CheckoutModal from "@/components/checkout-modal/CheckoutModal.vue";
import CleanSaleModal from "@/components/cart-modal/CleanSale.vue";

import { useBarcodeReaderStore } from "@/stores/barcode";
import { useCartStore } from "@/stores/cart";
import { useWarningStore } from "@/stores/warning";
import { useErrorStore } from "@/stores/error";

const router = useRouter();

const barcodeReader = useBarcodeReaderStore();
const cartStore = useCartStore();
const warningStore = useWarningStore();

const { barcode } = storeToRefs(barcodeReader);
const { setCanReadBarcode } = barcodeReader;
const { addProductToCart } = cartStore;

const state = reactive({
  isCartModalActive: false,
  isCheckoutModalActive: false,
  isCleanSaleModalActive: false,
  basketImage: "basket.svg",
});

const getImgUrl = (img) => {
  let images = require.context(
    "../assets/img/barcode-reading",
    false,
    /\.svg$/
  );
  return images("./" + img);
};

const openCheckoutAndCloseCartModal = () => {
  state.isCheckoutModalActive = true;
  setCanReadBarcode(false);
  closeCartModal;
};

const openCartModal = () => {
  state.isCartModalActive = true;
};

const closeCartModal = () => {
  state.isCartModalActive = false;
};

const closeCheckoutOpenCartModal = () => {
  state.isCheckoutModalActive = false;
  state.isCartModalActive = true;
  setCanReadBarcode(true);
};

const closeCheckoutAndCartModal = () => {
  state.isCheckoutModalActive = false;
  state.isCartModalActive = false;
  setCanReadBarcode(true);
};

const openCleanSaleModal = () => {
  state.isCleanSaleModalActive = true;
  setCanReadBarcode(false);
};

const closeCleanSaleModal = () => {
  state.isCleanSaleModalActive = false;
  setCanReadBarcode(true);

  if (cartStore.getTotalItemsQuantity === 0) {
    closeCartModal();
    router.replace("welcome");
  }
};

const isCartNotEmpty = computed(() => {
  if (cartStore.getTotalItemsQuantity !== 0) {
    return true;
  } else {
    return false;
  }
});

watch(barcode, async (newBarcode, oldBarcode) => {
  if (newBarcode && newBarcode !== "") {
    barcodeReader.setBarcode("");
    if (isCartNotEmpty.value === true && !state.isCartModalActive) {
      state.isCartModalActive = true;
    }
  }
});

onBeforeUnmount(() => {
  setCanReadBarcode(false);
});

onMounted(() => {
  setCanReadBarcode(true);
  if (isCartNotEmpty.value === true && !state.isCartModalActive) {
    state.isCartModalActive = true;
  }

  // if a sales error has ocurred, open checkout modal
  if (useErrorStore().getSalesErrorOccurence === true) {
    useErrorStore().setSalesErrorOccurence(false);
    openCheckoutAndCloseCartModal();
  }

  warningStore.resetCounter();

  // Disabling warning timer in development mode
  if (process.env.NODE_ENV === "production") {
    warningStore.startCounter();
    document
      .getElementById("barcode-read-page")
      .addEventListener("touchstart", () => {
        warningStore.resetCounter();
      });
  }

  if (process.env.NODE_ENV === "development") {
    setTimeout(() => {
      // Barcodes Wesley Store:
      // addProductToCart("070847022015");
      // addProductToCart("7891035618536");
      addProductToCart("7896423420180"); // SNICKERS

      if (!state.isCartModalActive) {
        state.isCartModalActive = true;
      }
    }, 500);
  }
});
</script>

<template>
  <div id="barcode-read-page" class="barcode-read-page">
    <top-navigation-bar v-if="!state.isCartModalActive" />

    <div class="div-bottom-elements" v-if="!state.isCartModalActive">
      <barcode-instructions />

      <div v-if="isCartNotEmpty" class="div-basket">
        <div @click="openCartModal">
          <img :src="getImgUrl(state.basketImage)" class="img-basket" />
          <div v-show="isCartNotEmpty" class="cart-quantity-circle">
            {{ cartStore.getTotalItemsQuantity }}
          </div>
        </div>
      </div>
      <div v-else class="div-basket">
        <img :src="getImgUrl(state.basketImage)" class="img-basket" />
      </div>
    </div>

    <cart-modal
      v-show="state.isCartModalActive"
      @close-cart-modal="closeCartModal"
      @open-clean-sale-modal="openCleanSaleModal"
      @open-checkout-and-close-cart-modal="openCheckoutAndCloseCartModal"
    />

    <checkout-modal
      v-if="state.isCheckoutModalActive"
      @close-checkout-and-cart-modal="closeCheckoutAndCartModal"
      @close-checkout-open-cart-modal="closeCheckoutOpenCartModal"
    />

    <clean-sale-modal
      v-if="state.isCleanSaleModalActive"
      @close-clean-sale-modal="closeCleanSaleModal"
    />
  </div>
</template>

<style scoped>
.barcode-read-page {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/img/barcode-reading/barcode-read-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.div-bottom-elements {
  top: 50%;
}
.div-basket {
  display: flex;
  justify-content: flex-end;
  margin-right: 4.06rem;
}
.img-basket {
  width: 5.125rem;
  height: 7.3125rem;
}
.cart-quantity-circle {
  position: absolute;
  left: -10px;
  bottom: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #039;
  border-radius: 1rem;
  z-index: 1;
  color: #fff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
