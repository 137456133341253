import { defineStore } from 'pinia'
import { setHttpLocale } from '../axios'

export const useLocaleStore = defineStore('locale', {
  state: () => ({
    locale: ""
  }),

  actions: {
    setLocale(locale) {
      this.locale = locale
      // setHttpLocale(locale) // TODO: verificar
    }
  }
})
